// import "./newform.scss";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Billsidebar from "../billsidebar/BillSidebar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ConnectionTable from "./../billTable/ConnectionTable";
import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
} from "../../store/allSlice";
import { ConnectionType } from "../../billFormData";
import PaginationButton from "../UI/PaginationButton";
import Navbar from "../navbar/Navbar";

const ConnectionForm = ({ Title, editTitle, axiosRoute }) => {
  let { data, showButton, Edit, field_error, search, count } = useSelector(
    (state) => state.area
  );
  document.title = "Connection";
  const dispatch = useDispatch();
  const [editId, setEditId] = useState(null);
  const [area, setArea] = useState({});
  const [editValue, setEditValue] = useState({});
  const [selectValue] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  let key = null;

  const onChangeHandler = (event, navigate) => {
    let { name, value, checked } = event.target;

    if (Edit) {
      setEditValue({
        ...editValue,
        [name]: event.target.type === "checkbox" ? checked : value,
        [key]: Object.values(selectValue)[1],
      });
    } else {
      setArea({
        ...area,
        [name]: event.target.type === "checkbox" ? checked : value,
        [key]: Object.values(selectValue)[1],
      });
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (Edit) {
      dispatch(editItem(editId, editValue, axiosRoute));
    } else {
      dispatch(postItem(area, axiosRoute));
    }
    dispatch(searchBarHandlerHide(true));
  };

  const EditHandler = (id) => {
    setEditId(id);
    let connectionObject = data.find((item) => item.id === id);
    setEditValue(connectionObject);
  };
  const handleReset = () => {
    setArea({});
    setEditValue({});
  };
  const AddHandler = () => {
    dispatch(setShowButton());
    handleReset();
    dispatch(dataAdd());
  };
  const GoBack = () => {
    dispatch(setShowButtonHide());
    dispatch(dataAdd());
  };
  return (
    <div className="newform">
      <Navbar />
      <Billsidebar />
      <div className="newContainer">
        <div className="top">
          <h1>{Edit ? editTitle : Title}</h1>
          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        <div className="showEntites">
          <span>show </span>
          <select onChange={(event) => setPostPerPage(event.target.value)}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span> entites</span>
        </div>
        {showButton ? (
          <div className="bottom">
            <div className="right">
              <form onSubmit={onSubmitHandler}>
                <div className="form_input">
                  {ConnectionType.map((input, index) => {
                    return (
                      <div className="formInput" key={input.id}>
                        <label>{input.label}</label>
                        <input
                          style={{
                            border:
                              input.db_name === field_error
                                ? "1px solid red"
                                : null,
                          }}
                          className={
                            input.readOnly ? "input_readOnly" : "input"
                          }
                          autoComplete="off"
                          value={
                            Edit
                              ? editValue[input.db_name]
                              : area[input.db_name]
                          }
                          onChange={onChangeHandler}
                          name={input.db_name}
                          checked={
                            Edit
                              ? editValue[input.db_name]
                              : area[input.db_name]
                          }
                          type={input.type}
                          placeholder={input.placeholder}
                          required={input.required}
                          readOnly={input.readOnly}
                          hidden={input.hidden}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="form_button">
                  <button className="button" type="submit">
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => GoBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            {
              <ConnectionTable
                EditHandler={EditHandler}
                axiosRoute={axiosRoute}
                currentPosts={currentPosts}
                setPostPerPage={setPostPerPage}
                indexOfFirstPost={indexOfFirstPost}
                indexOfLastPost={indexOfLastPost}
              />
            }
            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={search.trim() === "" ? data.length : count}
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectionForm;
