import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button} from '@mui/material'
import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const Alert = (props) => {
  return (
    <Dialog
    open={props.open}
    TransitionComponent={Transition}
    keepMounted
    onClose={props.model}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      আপনি কি ডাটা মুছে ফেলতে চাচ্ছেন ? সম্মতি থাকলে "সহমত" এ চাপুন|
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.model}>বাতিল</Button>
      <Button onClick={()=>props.areaDeleteHandler(props.id)}>সহমত</Button>
    </DialogActions>
  </Dialog>

  )
}

export default Alert