// import "./newform.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { fetchItem } from "../../store/allSlice";
import { ExpanseReportForm } from "../../billFormData";
import { useNavigate } from "react-router-dom";
import BillSidebar from "../billsidebar/BillSidebar";
import Navbar from "../navbar/Navbar";
import PrintIcon from "@mui/icons-material/Print";
import ExpanceReportTable from "../billTable/ExpanceReportTable";
import {
  currentDateYearMonth,
  previousDateYearMonth,
} from "../../store/customDate";

const ExpanceReport = ({ axiosRoute }) => {
  document.title = "Expance Report";
  const dispatch = useDispatch();
  let { data } = useSelector((state) => state.allinvoice);
  let BankReportData = data?.results;
  const navigate = useNavigate();
  const [allData, setAllData] = useState({});

  const onChangeHandler = (event) => {
    setAllData({
      ...allData,
      [event.target.name]: event.target.value,
    });
  };

  const onSearch = () => {
    let filter = "";
    let form_date = allData.from ? allData.from : previousDateYearMonth;
    let to_date = allData.to ? allData.to : currentDateYearMonth;
    if (allData.from === undefined || allData.from === "") {
      filter = `expanse-report/?form_date=${form_date}&to_date=${to_date}`;
    } else {
      filter = `expanse-report/?form_date=${form_date}&to_date=${to_date}`;
    }

    dispatch(fetchItem(filter));
  };
  const onSubmitHandler = (e) => {
    navigate("/admin/report/expansereportinvoice", { state: BankReportData });
  };

  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="bottom">
          <div className="right">
            <span style={{ color: "red" }}></span>
            <form onSubmit={onSubmitHandler}>
              <div className="form_input_invoice">
                {ExpanseReportForm.map((input, index) => {
                  return (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>

                      <input
                        autoComplete="off"
                        value={allData[input.db_name]}
                        onChange={onChangeHandler}
                        name={input.db_name}
                        type={input.type}
                        placeholder={input.placeholder}
                        required={input.required}
                        readOnly={input.readOnly}
                        hidden={input.hidden}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="form_button centerButton reportButton">
                <button
                  className="button"
                  type="button"
                  onClick={() => onSearch()}
                >
                  <SendIcon className="icon" />
                  Search
                </button>
                <button className="button" type="submit">
                  <PrintIcon className="icon" />
                  Print
                </button>
                <button
                  className="button"
                  type="button"
                  onClick={() => navigate("/admin/report")}
                >
                  <ArrowBackIosIcon className="icon" />
                  Go Back
                </button>
              </div>
            </form>
            <h2>খরচের রিপোর্ট</h2>
          </div>
        </div>
        <div className="Table">
          <ExpanceReportTable axiosRoute={axiosRoute} />
        </div>
      </div>
    </div>
  );
};

export default ExpanceReport;
