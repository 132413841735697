import { createSlice } from "@reduxjs/toolkit";
import Axios from "../Axios";
import toast from "react-hot-toast";
export const allSlice = createSlice({
  name: "areas",
  initialState: {
    data: [],
    customer: [],
    message: "",
    field_error: "",
    showButton: false,
    Edit: false,
    isLoading: true,
    searchBar: true,
    View: true,
    successMessage: "",
    search: "",
    count: 0,
    deleteError: "",
  },
  reducers: {
    setDeleteError: () => {
      state.deleteError = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    setSearchTermRemove: (state, action) => {
      state.search = "";
    },
    searchBarHandler: (state, action) => {
      state.searchBar = true;
    },
    searchBarHandlerHide: (state, action) => {
      state.searchBar = action.payload;
    },
    customerView: (state, action) => {
      state.View = true;
    },
    resetData: (state, action) => {
      state.data = [];
    },
    dataEdit: (state, action) => {
      state.Edit = true;
    },
    dataAdd: (state, action) => {
      state.Edit = false;
    },
    setShowButton: (state, action) => {
      state.showButton = true;
      // searchBarHandler();
    },
    setShowButtonHide: (state, action) => {
      state.showButton = false;
      state.message = "";
    },
    setErrorItem: (state, action) => {
      state.message = action.payload;
    },
    setErrorField: (state, action) => {
      state.field_error = action.payload;
    },
    setSuccessfulMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setFetchItem: (state, action) => {
      state.data = action.payload;
    },
    setFetchCustomerItem: (state, action) => {
      state.customer = action.payload;
    },
    // setFetchBillRegisterItem: (state, action) => {
    //   state.billregister = action.payload;
    // },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDeleteItem: (state, action) => {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    },
    setPostItem: (state, action) => {
      state.data.push(action.payload);
    },
    setEditItem: (state, action) => {
      const index = state.data.findIndex(
        (item) => item.id === action.payload.id
      );
      state.data[index] = {
        ...(state.data[index] = action.payload),
      };
    },
  },
});

export const {
  setCount,
  setDeleteError,
  setFetchCustomerItem,
  setSearchTerm,
  setFetchItem,
  setDeleteItem,
  setPostItem,
  setEditItem,
  setErrorItem,
  setErrorField,
  setShowButtonHide,
  setShowButton,
  dataEdit,
  dataAdd,
  resetData,
  customerView,
  changePath,
  setSuccessfulMessage,
  searchBarHandler,
  searchBarHandlerHide,
  setLoading,
  setSearchTermRemove,
} = allSlice.actions;
export default allSlice.reducer;
export const fetchItem = (url, id = "") => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      if (
        (url === "bill-register" ||
          url === "customer" ||
          url === "payment-collection") &&
        id !== ""
      ) {
        const response = await Axios.get(`/${url}/${id}`);
        dispatch(resetData([]));
        dispatch(setFetchItem(response.data));
      } else {
        const response = await Axios.get(`/${url}`);
        dispatch(setFetchItem(response.data));
        dispatch(setLoading(false));
      }
    } catch (error) {}
    // dispatch(searchBarHandler());
    // dispatch(dataAdd());
  };
};
export const deleteItem = (id, url) => {
  return async (dispatch, getState) => {
    let access_token = JSON.parse(window.localStorage.getItem("token") ?? "");
    try {
      await Axios.delete(`/${url}/${id}`, {
        headers: { Authorization: access_token?.refresh },
      });
      if (
        url === "loan" ||
        url === "loan-payment" ||
        url === "customer" ||
        url === "bill-register" ||
        url === "payment-collection" ||
        url === "employees" ||
        url === "employee-salary" ||
        url === "bank-deposit"
      ) {
        const response = await Axios.get(`/${url}/`);
        dispatch(setFetchItem(response.data));
      } else {
        dispatch(setDeleteItem(id));
      }
    } catch (error) {
      setDeleteError(error.response.data["message"]);
    }
  };
};
export const postItem = (postData, url) => {
  let access_token;
  if (localStorage.getItem("token")) {
    access_token = JSON.parse(window.localStorage.getItem("token") ?? "");
  }

  return async (dispatch, getState) => {
    try {
      const response = await Axios({
        method: "post",
        url: `/${url}/`,
        data: postData,
        headers: { Authorization: access_token?.refresh },
      });

      if (url === "login") {
        toast.success("Succssfully Login", { duration: 2000 });
      } else {
        toast.success("Successfully added Data", { duration: 1500 });
      }
      if (
        url === "loan" ||
        url === "loan-payment" ||
        url === "customer" ||
        url === "employees" ||
        url === "employee-salary" ||
        url === "bank-deposit" ||
        url === "register"
      ) {
        const response = await Axios.get(`/${url}/`);
        dispatch(setFetchItem(response.data));
      } else if (url === "bill-register") {
        const response = await Axios.get(`/${url}/`);
        dispatch(setFetchItem(response.data));

        return;
      } else if (url === "payment-collection") {
        const response = await Axios.get(`/${url}/`);
        dispatch(setFetchItem(response.data));
        return;
      } else if (url === "login") {
        localStorage.setItem("token", JSON.stringify(response.data?.token));
        localStorage.setItem("menu", JSON.stringify(response.data?.menus));
      } else {
        dispatch(setPostItem(response.data));
      }
      dispatch(setShowButtonHide());
    } catch (error) {
      dispatch(setErrorField(error.response.data["db_name"]));
      dispatch(setErrorItem(error.response.data["message"]));
      toast.error(
        error.response.data["message"] || error.response.data["db_name"],
        { duration: 2500 }
      );

      // localStorage.removeItem("status");
    }
  };
};
export const editItem = (id, data, url) => {
  return async (dispatch, getState) => {
    let access_token = JSON.parse(window.localStorage.getItem("token") ?? "");
    try {
      const response = await Axios.put(`/${url}/${id}`, data, {
        headers: { Authorization: access_token?.refresh },
      });
      if (url === "change-password") {
        toast.success("Succssfully password", { duration: 2000 });
      } else {
        toast.success("Successfully update", { duration: 3000 });
      }
      if (
        url === "loan" ||
        url === "loan-payment" ||
        url === "customer" ||
        url === "bill-register" ||
        url === "payment-collection" ||
        url === "employees" ||
        url === "employee-salary" ||
        url === "bank-deposit" ||
        url === "register"
      ) {
        const response = await Axios.get(`/${url}/`);
        dispatch(setFetchItem(response.data));
      } else {
        dispatch(setEditItem(response.data));
      }

      dispatch(setShowButtonHide());
    } catch (error) {
      dispatch(setErrorItem(error.response.data["message"]));
    }
  };
};
