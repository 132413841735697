// import "./featured.scss";
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const Featured = ({totalBillRegisterAmount,totalBillPaidAmount}) => {
  let percentage = Math.round((totalBillPaidAmount * 100) / totalBillRegisterAmount)
  
  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Revenue</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <CircularProgressbar value={percentage} text={`${percentage}%`} strokeWidth={5} />
        </div>
        <p className="title">parcentage collection balance</p>
        <p className="amount">&#2547;{totalBillPaidAmount}</p>
        <p className="desc">
          Total paid bill collection balance every month
        </p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Target</div>
            <div className="itemResult negative">
              <KeyboardArrowDownIcon fontSize="small" />
              {/* <div className="resultAmount">&#2547; 12.4k</div> */}
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              {/* <div className="resultAmount">&#2547; 12.4k</div> */}
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              {/* <div className="resultAmount"> &#2547; 12.4k</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
