import React, { useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyIcon from "@mui/icons-material/Key";
import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchTerm } from "../../store/allSlice";

import Axios from "../../Axios";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatchs = useDispatch();
  const [showList, setShowList] = useState(false);
  const { dispatch } = useContext(DarkModeContext);
  let { searchBar, search } = useSelector((state) => state.area);

  const noticeHandler = () => {
    alert("Default Language English");
  };

  const logout = async () => {
    let access_token;
    if (localStorage.getItem("token")) {
      access_token = JSON.parse(localStorage.getItem("token") ?? "");
    }

    try {
      await Axios.post("logout/", {
        refresh: access_token.refresh,
      });
      localStorage.clear();
      navigate("/admin/login", { replace: true });
    } catch (error) {}
  };
  const setSearchTermHandler = (e) => {
    dispatchs(setSearchTerm(e.target.value));
  };
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          {searchBar && (
            <div className="searchBar">
              <input
                id="all_search_bar"
                type="text"
                value={search}
                onChange={(e) => setSearchTermHandler(e)}
                placeholder="Search..."
              />
              <SearchOutlinedIcon className="icon" />
            </div>
          )}
        </div>
        <div className="items">
          <div className="item" onClick={() => noticeHandler()}>
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <img
              onClick={() => setShowList((prev) => !prev)}
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            />
            {showList ? (
              <div
                className="dropdown"
                style={{
                  display: "block",
                  background: "white",
                  fontSize: "13px",
                  width: "200px",
                  margin: "120px 0 0 -140px",
                  padding: "8px",
                  position: "fixed",
                  boxShadow: "1px 1px",
                }}
              >
                <div
                  className="dropdownItem"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/admin/changepassword")}
                >
                  <span>Change Password</span>{" "}
                  <KeyIcon
                    className="icon"
                    style={{ width: "16px", height: "16px" }}
                  />
                </div>
                <br />
                <div
                  className="dropdownItem"
                  onClick={() => logout()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>Logout</span>{" "}
                  <LogoutIcon
                    className="icon"
                    style={{ width: "16px", height: "16px" }}
                  />
                </div>
              </div>
            ) : // <ul
            //   style={{
            //     position: "fixed",
            //     listStyleType: "none",
            //     margin: "100px 0 0 0",
            //     zIndex: 100,
            //   }}
            // >
            //   <li
            //     // onClick={() => logout()}
            //     style={{
            //       backgroundColor: "#fff",
            //       color: "#000  ",
            //       display: "flex",
            //       justifyContent: "center",
            //       alignItems: "center",
            //       gap:"0 8px",
            //     }}
            //   >
            //     {" "}
            //     <span style={{fontSize:'16px'}}>Change Password</span> <LogoutIcon color="action" style={{width:"21px",height:"21px"}}/>
            //   </li>
            //   <li
            //     onClick={() => logout()}
            //     style={{
            //       backgroundColor: "#fff",
            //       color: "#000  ",
            //       display: "flex",
            //       justifyContent: "center",
            //       alignItems: "center",
            //     }}
            //   >
            //     {" "}
            //     <span style={{fontSize:'16px'}}>Logout</span>  <LogoutIcon color="action" />
            //   </li>
            // </ul>
            null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
