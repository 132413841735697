// import "./newform.scss";
import React from "react";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { useNavigate } from "react-router-dom";
import BillSidebar from "../billsidebar/BillSidebar";
import Navbar from "../navbar/Navbar";

const Reports = () => {
  const navigate = useNavigate();

  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="bottom">
          <div className="right">
            <div className="card_section">
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/bill-report")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                বিল রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/paid-bill-report")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                পরিশোধিত বিল রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/unpaid-bill-report")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                অপরিশোধিত বিল রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/bankreport")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                ব্যাংকে টাকা জমা দেওয়ার রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/unit-report")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                ইউনিট রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/expansereport")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                খরচের রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/expanceincomereport")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                আয় এবং খরচের রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/simplereport")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                আয় এবং খরচের সংক্ষেপ রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/cashreport")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                ক্যাশ ভারসাম্য রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/loanreport")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                ঋণ রিপোর্ট
              </div>
              <div
                className="card_button"
                onClick={() => navigate("/admin/report/sub-meter-report")}
              >
                <ReceiptOutlinedIcon style={{ marginRight: "10px" }} />
                সাব মিটার রিপোর্ট
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
