import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableHead from "@mui/material/TableHead";
import UserTable from "../../components/table/UserTable";
import {
  CustomDayMonthYear,
  CustomYearMonth,
  DigitConvert,
} from "../../store/customDate";

const Users = () => {
  const { data } = useSelector((state) => state.user);
  const customer = data?.customer;

  const bills = data?.bills;

  const navigate = useNavigate();
  return (
    <div className="home">
      <div className="homeContainerUser mobileVersion">
        <div className="listContainer">
          <div
            className="listTitle"
            style={{
              background: "white",
              padding: "12px",
              marginBottom: "2px",
            }}
          >
            Consumer Information
          </div>

          {Object.keys(data).length > 0 ? (
            <>
              <TableContainer component={Paper} className="table">
                <div style={{ height: "100%", padding: "2%" }}>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow sx={{ border: "none" }}>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          <strong>গ্রাহকের নাম</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          {customer?.customer_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          <strong>গ্রাহক নং</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          {customer?.customer_no
                            ? DigitConvert(customer?.customer_no)
                            : null}
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          <strong>সংযোগের ধরন</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          {customer?.connection_type}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ border: "none" }}>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          <strong>পিতা/স্বামী নাম</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          {customer?.guardian_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          <strong>মিটার নং</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          {customer?.meter_no
                            ? DigitConvert(customer?.meter_no)
                            : null}
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          <strong>শাখা</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          {customer?.branch}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ border: "none" }}>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          <strong>ঠিকানা</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          {customer?.address}
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          <strong>সংযোগের তারিখ</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        >
                          {customer?.connection_date
                            ? CustomDayMonthYear(customer?.connection_date)
                            : null}
                        </TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{
                            lineHeight: "100%",
                            border: "none",
                            width: "10%",
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </TableContainer>
              <TableContainer
                style={{ marginTop: "1.5rem" }}
                component={Paper}
                className="table"
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className="row_padding">
                      <TableCell colSpan={"100%"}>
                        Monthly Bill History
                      </TableCell>
                    </TableRow>
                    <TableRow className="row_padding">
                      <TableCell className="user_tableCell">
                        ইনভয়েস নং
                      </TableCell>
                      <TableCell className="user_tableCell">
                        বিলের মাস
                      </TableCell>
                      <TableCell className="user_tableCell">
                        বিল তৈরির তারিখ
                      </TableCell>
                      <TableCell className="user_tableCell">মিটার নং</TableCell>
                      <TableCell className="user_tableCell">
                        ব্যবহিত ইউনিট
                      </TableCell>
                      <TableCell className="user_tableCell">মোট বিল</TableCell>
                      <TableCell className="user_tableCell">
                        পরিশোধিত বিল
                      </TableCell>
                      <TableCell className="user_tableCell">ছাড়</TableCell>
                      <TableCell className="user_tableCell">
                        {" "}
                        পরিশোধিত / অপরিশোধিত
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    {bills?.map((row, index) => (
                      <TableRow key={index} className="row_padding">
                        <TableCell className="user_tableCell">
                          {row?.invoice_no
                            ? DigitConvert(row?.invoice_no)
                            : null}
                        </TableCell>
                        <TableCell className="user_tableCell">
                          {row?.month ? CustomYearMonth(row?.month) : null}
                        </TableCell>
                        <TableCell className="user_tableCell">
                          {row?.created_date
                            ? CustomDayMonthYear(row?.created_date)
                            : null}
                        </TableCell>
                        <TableCell className="user_tableCell">
                          {row?.meter_no ? DigitConvert(row?.meter_no) : null}
                        </TableCell>
                        <TableCell className="user_tableCell">
                          {row?.uses_unit ? DigitConvert(row?.uses_unit) : null}
                        </TableCell>
                        <TableCell className="user_tableCell">
                          {row?.total_bill
                            ? DigitConvert(row?.total_bill)
                            : null}
                        </TableCell>
                        <TableCell className="user_tableCell">
                          {row?.paid_amount
                            ? DigitConvert(row?.paid_amount)
                            : null}
                        </TableCell>
                        <TableCell className="user_tableCell">
                          {row?.discount ? DigitConvert(row?.discount) : null}
                        </TableCell>
                        <TableCell className="user_tableCell">
                          {row?.paid_amount ? "paid" : row?.status}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableHead>
                </Table>
              </TableContainer>
            </>
          ) : (
            <h1>
              {" "}
              বিদ্যুৎ বিলের হিসাব নং এবং মিটার নং পাওয়া যায়নি !{" "}
              <button onClick={() => navigate("/")}>
                আবার চেষ্টা করুন
              </button>{" "}
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
