// import "./widget.scss";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";

const Widget = ({
  type,
  customerdata,
  monthlyBillRegister,
  billRegisterMonthlyAmount,
  billRegisterDiscountAmount,
  paidMonthlyAmount,
}) => {
  let data;
  let bill = 0;
  let diff = 20;
  let amt = 0;

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "Total Active User",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "REGISTER",
        isMoney: false,
        link: "Monthly Bill Register Count",
        icon: (
          <NetworkCheckIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "AMOUNT",
        isMoney: true,
        link: "Monthty Bill Register Amount",
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
      case "discount":
        data = {
          title: "DISCOUNT",
          isMoney: true,
          link: "Monthly Bill Discount Balance",
          icon: (
            <AccountBalanceWalletOutlinedIcon
              className="icon"
              style={{
                backgroundColor: "rgba(128, 0, 128, 0.2)",
                color: "goldenrod",
              }}
            />
          ),
        };
        break;
    case "balance":
      data = {
        title: "BALANCE",
        isMoney: true,
        link: "Monthly Paid Balance",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }
  let datas = null;

  if (data.title === "USERS") {
    bill = Number(customerdata);
    diff = Math.round((bill / Number(customerdata)) * 100);
    datas = (
      <span>
        {" "}
        {data.isMoney && <span>&#2547;</span>} {customerdata}
      </span>
    );
  } else if (data.title === "REGISTER") {
    bill = Number(monthlyBillRegister);
    diff = Math.round(bill / 100);
    datas = (
      <span>
        {" "}
        {data.isMoney && <span>&#2547;</span>} {monthlyBillRegister}
      </span>
    );
    
  } else if (data.title === "DISCOUNT") {
    amt = 5;
    diff = 100;
    datas = (
      <span>
        {" "}
        {data.isMoney && <span>&#2547;</span>} {billRegisterDiscountAmount}
      </span>
    );
  }else if (data.title === "AMOUNT") {
    amt = 5;
    diff = 100;
    datas = (
      <span>
        {" "}
        {data.isMoney && <span>&#2547;</span>} {billRegisterMonthlyAmount}
      </span>
    );
  }
   else {
    diff = Math.round(isNaN(paidMonthlyAmount / amt) * 100);
    datas = (
      <span>
        {" "}
        {data.isMoney && <span>&#2547;</span>} {paidMonthlyAmount}
      </span>
    );
  }
  const programmaticleHandler = (e, data) => {
    e.preventDefault();
    if (data === "USERS") {
      // history("/add_customer", { replace: true });
    }
    if (data === "METER") {
      // history("customer", { replace: true });
    }
  };

  return (
    <div
      className="widget"
      style={{ textDecoration: "None" }}
      onClick={(e) => programmaticleHandler(e, data.title)}
    >
      {/* <div className="widget" > */}
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">{datas}</span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
