// import "./newform.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { fetchItem } from "../../store/allSlice";
import { AllInvoice } from "../../billFormData";
import { useNavigate } from "react-router-dom";
import BillSidebar from "../billsidebar/BillSidebar";
import Navbar from "../navbar/Navbar";

const PrintAllForm = ({ axiosRoute }) => {
  let { data } = useSelector((state) => state.area);
  document.title = "Print All invoice";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [allData, setAllData] = useState({ from: "", to: "", month: "" });

  const onChangeHandler = (event) => {
    setAllData({
      ...allData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    let billmonth = allData.month ? allData.month : "empty";
    if (allData.from && allData.to) {
      dispatch(
        fetchItem(
          `print-invoice/?invoice_form=${allData.from}&invoice_to=${allData.to}&bill_month=${billmonth}`
        )
      );
    } else if (allData.from && !allData.to) {
      dispatch(
        fetchItem(
          `print-invoice/?invoice_form=${allData.from}&bill_month=${billmonth}`
        )
      );
    } else if (!allData.from && allData.to) {
      dispatch(
        fetchItem(
          `print-invoice/?&invoice_to=${allData.to}&bill_month=${billmonth}`
        )
      );
    } else {
      dispatch(fetchItem(`print-invoice/?bill_month=${billmonth}`));
    }

    setTimeout(() => {
      navigate("/admin/printallinvoice", { state: data });
    }, 1000);
    //  else {
    //   const filterData = data.filter(
    //     (item) =>
    //       item.invoice_no >= allData.from &&
    //       item.customer_no <= allData.to &&
    //       item.month === allData.month
    //   );
    //   navigate("/admin/printallinvoice", { state: filterData });
    // }
  };

  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="bottom">
          <div className="right">
            <form onSubmit={onSubmitHandler}>
              <div className="form_input_invoice">
                {AllInvoice.map((input, index) => {
                  return (
                    <div className="formInput" key={input.id}>
                      <label>{input.label}</label>
                      <input
                        autoComplete="off"
                        value={allData[input.db_name]}
                        onChange={onChangeHandler}
                        name={input.db_name}
                        type={input.type}
                        placeholder={input.placeholder}
                        required={input.required}
                        readOnly={input.readOnly}
                        hidden={input.hidden}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="form_button centerButton">
                <button className="button" type="submit">
                  <SendIcon className="icon" />
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintAllForm;
