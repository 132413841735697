import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postItem } from "../../store/allSlice";
import { Navigate, useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import BillSidebar from "../billsidebar/BillSidebar";

const ChangePassword = ({ axiosRoute }) => {
  let clearTime;
  const navigate = useNavigate();
  // const { data } = useSelector((state) => state.area);
  const dispatch = useDispatch();
  const [input, setInput] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "oldPassword":
          if (!value) {
            stateObj[name] = "Please enter Old password.";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();

    if (
      input.oldPassword !== "" &&
      input.password !== "" &&
      input.confirmPassword !== ""
    ) {
      const resetPassword = {
        old_password: input.oldPassword,
        password: input.password,
        password2: input.confirmPassword,
      };
      dispatch(postItem(resetPassword, axiosRoute));

      clearTime = setTimeout(() => {
        localStorage.clear();
        navigate("/admin/login");
      }, 1000);
      return;
    }

    return;
  };
  clearTimeout(clearTime);

  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="loginMainContainer" style={{ background: "None" }}>
        <div
          className="loginContainer"
          style={{
            width: "100%",
            top: 0,
            left: 0,
            boxShadow: " 8px 2px 10px 0px rgba(61, 61, 62, 0.2)",
          }}
        >
          <form onSubmit={SubmitHandler}>
            <div className="loginInputContainer" style={{ width: "40%" }}>
              <div style={{ marginTop: "15px" }}>
                <label>Old Password</label>
                <input
                  className="loginInput"
                  type="password"
                  name="oldPassword"
                  placeholder="Enter Username"
                  value={input.oldPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                ></input>
                {error.oldPassword && (
                  <span className="err">{error.oldPassword}</span>
                )}
              </div>
              <div style={{ marginTop: "15px" }}>
                <label>New Password</label>
                <input
                  className="loginInput"
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  value={input.password}
                  onChange={onInputChange}
                  onBlur={validateInput}
                ></input>
                {error.password && (
                  <span className="err">{error.password}</span>
                )}
              </div>
              <div style={{ marginTop: "15px" }}>
                <label>Confirm Password</label>
                <input
                  className="loginInput"
                  type="password"
                  name="confirmPassword"
                  placeholder="Enter Confirm Password"
                  value={input.confirmPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                ></input>
                {error.confirmPassword && (
                  <span className="err">{error.confirmPassword}</span>
                )}
              </div>
              <br />
              <button type="submit" className="loginButton">
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
