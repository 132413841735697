// import "./newform.scss";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoanTable from "./../billTable/LoanTable";
import Billsidebar from "../billsidebar/BillSidebar";
// import Spiner from "../UI/Spiner";
import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
} from "../../store/allSlice";
import { Loan } from "../../billFormData";
import PaginationButton from "../UI/PaginationButton";
import { CustomDate } from "../../store/customDate";
import Navbar from "../navbar/Navbar";

const LoanForm = ({
  searchTerm,
  Title,
  setSearchTerm,
  editTitle,
  axiosRoute,
}) => {
  let { data, showButton, Edit, field_error, search } = useSelector(
    (state) => state.loan
  );

  document.title = "Loan";
  const dispatch = useDispatch();

  const [editId, setEditId] = useState(null);
  const [allData, setAllData] = useState({});
  const [editValue, setEditValue] = useState({});
  const [selectValue, setSelectValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [loanId, setLoanId] = useState(null);

  let loanData = data?.loans;
  let loanGiverData = data?.loan_givers;
  let Loan_id = data?.loan_no;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = (loanData ?? data).slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  let key = null;

  const onChangeHandler = (event, navigate) => {
    let { name, value, checked } = event.target;
    if (Edit) {
      setEditValue({
        ...editValue,
        [name]: event.target.type === "checkbox" ? checked : value,
        [key]: selectValue.value,
      });
    } else {
      setAllData({
        ...allData,
        loan_no: event.target.name === "loan_no" ? event.target.value : loanId,
        [name]: event.target.type === "checkbox" ? checked : value,
        [key]: selectValue.value,
      });
    }
  };

  const onChangeSelectedValue = (e) => {
    if (Edit) {
      setEditValue({
        ...editValue,
        [key]: e.value,
      });
    } else {
      setAllData({
        ...allData,
        [key]: e.value,
      });
    }
    return setSelectValue(e);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (Edit) {
      dispatch(editItem(editId, editValue, axiosRoute));
    } else {
      dispatch(postItem(allData, axiosRoute));
    }
    dispatch(searchBarHandlerHide(true));
  };

  const goBack = () => {
    dispatch(setShowButtonHide());
    dispatch(dataAdd());
    setSelectValue({});
  };

  const EditHandler = (id) => {
    setEditId(id);
    let loanObject = loanData.find((item) => item.id === id);

    setEditValue(loanObject);
  };
  const handleReset = () => {
    setSelectValue({});
    setAllData({});
    setEditValue({});
  };
  const AddHandler = () => {
    setLoanId(Loan_id);
    dispatch(setShowButton());
    handleReset();
    dispatch(dataAdd());
  };

  return (
    <div className="newform">
      <Navbar />
      <Billsidebar />

      <div className="newContainer">
        <div className="top">
          <h1>{showButton ? editTitle : Title}</h1>

          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        <div className="showEntites">
          <span>show </span>
          <select onChange={(event) => setPostPerPage(event.target.value)}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span> entites</span>
        </div>

        {showButton ? (
          <div className="bottom">
            <div className="right">
              <form id="clear_data" onSubmit={onSubmitHandler}>
                <div className="form_input">
                  {Loan.map((input, index) => {
                    if (input.type === "select") {
                      key = input.db_name;
                      const lgSelectData = loanGiverData.filter(
                        (item) => item.value === editValue[input.db_name]
                      );

                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <Select
                            className="search_dropdown"
                            name={input.db_name}
                            value={
                              Edit && Object.keys(selectValue).length === 0
                                ? lgSelectData
                                : Object.keys(selectValue).length === 0
                                ? loanGiverData[0]
                                : selectValue
                            }
                            defaultValue={loanGiverData[0]}
                            onChange={(e) => onChangeSelectedValue(e)}
                            options={loanGiverData}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <input
                            style={{
                              border:
                                input.db_name === field_error
                                  ? "1px solid red"
                                  : null,
                            }}
                            className={
                              input.readOnly ? "input_readOnly" : "input"
                            }
                            autoComplete="off"
                            value={
                              (Edit && input.type === "date"
                                ? CustomDate(editValue[input.db_name])
                                : allData[input.db_name]) ??
                              (Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name] || loanId)
                            }
                            onChange={onChangeHandler}
                            name={input.db_name}
                            checked={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                            }
                            type={input.type}
                            placeholder={input.placeholder}
                            required={input.required}
                            readOnly={input.readOnly}
                            hidden={input.hidden}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="form_button">
                  <button className="button" type="submit">
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => goBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            <LoanTable
              EditHandler={EditHandler}
              axiosRoute={axiosRoute}
              currentPosts={currentPosts}
              setCount={setCount}
              setPostPerPage={setPostPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
            />

            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={search.trim() === "" ? loanData?.length : count}
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default LoanForm;
