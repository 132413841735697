import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchItem,
  dataEdit,
  setShowButton,
  deleteItem,
  setCount,
} from "../../store/allSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Alert from "../UI/Alert";
import Spiner from "../UI/Spiner";

const CustomerTable = ({
  EditHandler,
  axiosRoute,
  currentPosts,
  indexOfFirstPost,
  indexOfLastPost,
}) => {
  let { data, isLoading, search } = useSelector((state) => state.area);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [indexId, setIndexId] = useState(null);
  const [title, setTitle] = useState(null);

  let keys = [
    "customer_no",
    "customer_name",
    "guardian_name",
    "address",
    "mobile_no",
  ];

  let customerData = data?.customer;

  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
  }, [dispatch]);

  const deleteHandler = (id) => {
    dispatch(deleteItem(id, axiosRoute));

    setOpen(false);
  };
  const model = () => {
    setOpen((prevState) => !prevState);
  };
  const editHandler = (id) => {
    dispatch(fetchItem(axiosRoute, id));
    EditHandler(id);
    dispatch(dataEdit());
    dispatch(setShowButton());
  };

  function renderItemFunction() {
    let searchItem;

    function dataSearch() {
      let searchArea = customerData?.filter((row) =>
        keys.some((key) => {
          if (row[key] === null || row[key] === undefined) {
            searchItem = "";
          } else {
            searchItem = row[key]
              .toLowerCase()
              .includes(search.trim().toLowerCase());
          }

          return searchItem;
        })
      );
      let searchAreaData = searchArea.slice(indexOfFirstPost, indexOfLastPost);

      dispatch(setCount(searchArea.length));

      return searchAreaData;
    }

    return (search.trim() === "" ? currentPosts : dataSearch()).map((row) => {
      return (
        <TableRow key={row.id}>
          <TableCell className="tableCell">{row.customer_no}</TableCell>
          <TableCell className="tableCell">{row.customer_name}</TableCell>
          <TableCell className="tableCell">{row.guardian_name}</TableCell>
          <TableCell className="tableCell">{row.gender}</TableCell>
          <TableCell className="tableCell">{row.address}</TableCell>
          <TableCell className="tableCell">{row.mobile_no}</TableCell>
          <TableCell className="tableCell">{row.connection}</TableCell>
          <TableCell className="tableCell">{row.meter_no}</TableCell>
          <TableCell className="tableCell">
            <div className="group_button">
              <button
                onClick={() => editHandler(row.id)}
                className="button_table"
                aria-label="delete"
              >
                <ModeEditIcon className="edit_icon" />
              </button>
              <button
                onClick={() => {
                  model();
                  setIndexId(row.id);
                  setTitle(`${row.customer_name}/${row.meter_no}`);
                }}
                className="button_table"
                aria-label="delete"
              >
                <DeleteOutlineIcon className="delete_icon" />
              </button>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">গ্রাহক নং </TableCell>
                <TableCell className="tableCell">গ্রাহকের নাম </TableCell>
                <TableCell className="tableCell">পিতা/স্বামী নাম </TableCell>
                <TableCell className="tableCell">গ্রাহক লিঙ্গ </TableCell>
                <TableCell className="tableCell">ঠিকানা </TableCell>
                <TableCell className="tableCell">মোবাইল নং </TableCell>
                <TableCell className="tableCell">সংযোগের ধরন </TableCell>
                <TableCell className="tableCell">মিটার নং </TableCell>
                <TableCell className="tableCell_actions">কর্ম </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>{renderItemFunction()}</TableBody>
          </Table>

          {open && (
            <Alert
              areaDeleteHandler={deleteHandler}
              open={open}
              model={model}
              title={title}
              id={indexId}
            />
          )}
        </TableContainer>
      )}
    </>
  );
};

export default React.memo(CustomerTable);
