import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Spiner from "../UI/Spiner";
import { useSelector, useDispatch } from "react-redux";
import { fetchItem } from "../../store/allSlice";
import { DigitConvert } from "../../store/customDate";

const BillReportTable = ({ axiosRoute }) => {
  const dispatch = useDispatch();
  let { data, isLoading } = useSelector((state) => state.area);

  let result = data?.results;

  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
  }, [dispatch]);

  let discount = 0;
  let paidBill = 0;
  let totalBill = 0;

  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">ক্রমিক নং</TableCell>
                <TableCell className="tableCell">বিল তৈরির তারিখ</TableCell>
                <TableCell className="tableCell">গ্রাহক নং</TableCell>
                <TableCell className="tableCell">গ্রাহকের নাম</TableCell>
                <TableCell className="tableCell">বিলের মাস</TableCell>
                <TableCell className="tableCell">মোট বিল</TableCell>
                <TableCell className="tableCell">
                  পরিশোধিত টাকার পরিমান
                </TableCell>
                <TableCell className="tableCell">ছাড়</TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {result?.map((item, index) => {
                let TotalAmount = parseInt(
                  item.net_bill === null ? 0 : item.net_bill
                );
                let PaidAmount = parseInt(
                  item.paid_amount == null ? 0 : item.paid_amount
                );
                let DiscountAmount = parseInt(
                  item.discount === null ? 0 : item.discount
                );

                if (
                  "total_bill" in item &&
                  "paid_amount" in item &&
                  "discount" in item
                ) {
                  totalBill = totalBill + TotalAmount;
                  paidBill = paidBill + PaidAmount;
                  discount = discount + DiscountAmount;
                }
                return (
                  <TableRow key={index}>
                    <TableCell className="tableCell">
                      {DigitConvert(index + 1)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.created_date_bn}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.customer_no_bn}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.customer_name}
                    </TableCell>
                    <TableCell className="tableCell">{item.month_bn}</TableCell>
                    <TableCell className="tableCell">
                      {item.net_bill_bn}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.paid_amount_bn === "None"
                        ? ""
                        : DigitConvert(item.paid_amount)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {DigitConvert(item.discount)}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>মোট</TableCell>
                <TableCell>{DigitConvert(totalBill)}</TableCell>
                <TableCell>{DigitConvert(paidBill)}</TableCell>
                <TableCell>{DigitConvert(discount)}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default BillReportTable;
