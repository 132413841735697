import React from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import Spiner from "../UI/Spiner";
import { DigitConvert } from "../../store/customDate";

const PaidBillReportTable = ({ items }) => {
  const { data, isLoading } = useSelector((state) => state.area);
  let SearchCustomerResult = data?.results;
  let totalBill = 0;
  let paidBill = 0;
  let discount = 0;
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">ক্রমিক নং</TableCell>
                <TableCell className="tableCell">বিল তৈরির তারিখ</TableCell>
                <TableCell className="tableCell">গ্রাহক নং</TableCell>
                <TableCell className="tableCell">গ্রাহকের নাম</TableCell>
                <TableCell className="tableCell">বিলের মাস</TableCell>
                <TableCell className="tableCell">মোট বিল</TableCell>
                <TableCell className="tableCell">
                  পরিশোধিত টাকার পরিমান
                </TableCell>
                <TableCell className="tableCell">ছাড়</TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {SearchCustomerResult?.map((item, index) => {
                let TotalAmount = parseInt(item.total_bill);
                let PaidAmount = parseInt(item.paid_amount);
                let DiscountAmount = parseInt(item.discount);
                if (
                  "total_bill" in item &&
                  "paid_amount" in item &&
                  "discount" in item
                ) {
                  totalBill = totalBill + TotalAmount;
                  paidBill = paidBill + PaidAmount;
                  discount = discount + DiscountAmount;
                }

                return (
                  <TableRow key={item.id}>
                    <TableCell className="tableCell">
                      {DigitConvert(index + 1)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.created_date_bn}
                    </TableCell>
                    <TableCell className="tableCell">
                      {DigitConvert(item.customer_no)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.customer_name}
                    </TableCell>
                    <TableCell className="tableCell">{item.month_bn}</TableCell>
                    <TableCell className="tableCell">
                      {DigitConvert(item.total_bill)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {DigitConvert(item.paid_amount)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {DigitConvert(item.discount)}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>মোট</TableCell>
                <TableCell>{DigitConvert(totalBill)}</TableCell>
                <TableCell>{DigitConvert(paidBill)}</TableCell>
                <TableCell>{DigitConvert(discount)}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default PaidBillReportTable;
