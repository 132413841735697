const ErrorPage=()=>{
    return (
        <div className="errorContainer">
        <div class="container">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
        <a href="/">Go Back Home</a>
    </div>
    </div>
    )
}

export default ErrorPage