import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "beforeunload",
];
export default function AppLogout({ children }) {
  const navigate = useNavigate();
  const fiveMin = 5 * 60 * 1000;
  let timer;
  const handlerLogoutTime = () => {
    timer = setTimeout(() => {
      resetTime();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTime);
      });
      logoutAction();
    }, fiveMin);
  };
  const resetTime = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTime();
        handlerLogoutTime();
      });
    });
  }, []);

  const logoutAction = () => {
    localStorage.clear();
    navigate("/admin/login", { replace: true });
  };

  return children;
}
