import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Billsidebar from "../billsidebar/BillSidebar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Select from "react-select";
import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  dataEdit,
  searchBarHandlerHide,
} from "../../store/allSlice";
import PaginationButton from "../UI/PaginationButton";
import Navbar from "../navbar/Navbar";
import AccountRoleTable from "../billTable/AccountRoleTable";

const AccountRoleForm = ({ Title, editTitle, axiosRoute }) => {
  document.title = "Account Role";
  let { data, showButton, Edit, search } = useSelector(
    (state) => state.accountRole
  );

  let usersData = data?.users;

  let employeeId = data?.employees;
  let userType = data?.user_type;

  let area = data?.permissions?.area;
  let account = data?.permissions?.account;
  let bank = data?.permissions?.bank;
  let bill_register = data?.permissions?.bill_register;
  let bank_deposit = data?.permissions?.bank_deposit;
  let branch = data?.permissions?.branch;
  let connection_type = data?.permissions?.connection_type;
  let customer = data?.permissions?.customer;
  let designation = data?.permissions?.designation;
  let employee = data?.permissions?.employee;
  let employee_salary = data?.permissions?.employee_salary;
  let expanse = data?.permissions?.expanse;
  let loan = data?.permissions?.loan;
  let loan_giver = data?.permissions?.loan_giver;
  let loan_payment = data?.permissions?.loan_payment;
  let payment_collection = data?.permissions?.payment_collection;

  let menus = data?.menus;

  const dispatch = useDispatch();
  const [input, setInput] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [menusData, setMenusData] = useState({});

  const [selectParmission, setSelectParmission] = useState([]);
  const [editParmission, setEditParmission] = useState([]);

  const [editId, setEditId] = useState(null);
  const [editValue, setEditValue] = useState({});
  const [selectEmployee, setSelectEmployee] = useState({});
  const [selectType, setSelectType] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = (usersData ?? data)?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Please enter username";
          } else {
            stateObj["username"] = input.username ? "" : error.confirmPassword;
          }
          break;
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  const isMenu = (menu = "", submenu = "") => {
    if (Edit) {
      if (menu !== "" && submenu === "") {
        let _menuchecked = menusData.filter(
          (parentItem) =>
            parentItem.title === menu && parentItem.is_active === false
        );

        return Object.keys(_menuchecked).length < 1 ? false : true;
      } else if (menu !== "" && submenu !== "") {
        let _menu = menusData.find((parentItem) => parentItem.title === menu);
        let _submenuchecked = _menu.childrens.find(
          (parentItem) =>
            parentItem.title === submenu && parentItem.is_active === false
        );

        return _submenuchecked !== undefined ? true : false;
      }
    }
    // return false
  };

  const onChangeHideMenuHandler = (e, hasChild = "") => {
    if (hasChild === "") {
      setMenusData((prev) => {
        let tempData = prev;
        return tempData.map((item) => ({
          ...item,
          is_active:
            item.title === e.target.name ? !item.is_active : item.is_active,
        }));
      });
    } else {
      setMenusData((prevData) => {
        let tempMenuData = prevData;
        let tempData = [];

        tempMenuData.forEach((menu) => {
          if ("childrens" in menu && hasChild === menu.title) {
            let vl = menu.childrens.map((item) => ({
              ...item,
              is_active:
                item.title === e.target.name ? !item.is_active : item.is_active,
            }));
            let obj = {
              id: menu.id,
              title: menu.title,
              path: menu.path,
              is_active: menu.is_active,
              childrens: vl,
            };
            tempData.push(obj);
          } else {
            tempData.push(menu);
          }
        });
        return tempData;
      });
    }
  };

  const onChangeHandler = (event) => {
    // let { name, value, checked } = event.target;

    if (Edit) {
      if (event && !editParmission) {
        setEditParmission([event]);
      } else if (editParmission.includes(event)) {
        setEditParmission([...editParmission.filter((i) => i !== event)]);
      } else {
        setEditParmission([...editParmission, event]);
      }
      setEditValue({
        ...editValue,

        // [name]: event.target.type === "checkbox" ? checked : value,
      });
    } else {
      if (event && !selectParmission) {
        setSelectParmission([event]);
      } else if (selectParmission.includes(event)) {
        setSelectParmission([...selectParmission.filter((i) => i !== event)]);
      } else {
        setSelectParmission([...selectParmission, event]);
      }
    }
  };

  const onSubmitHandler = (e) => {
    const submitdata = {
      employee_id: selectEmployee.value,
      role: selectType.value,
      user_name: input.username,
      password: input.password,
      password2: input.confirmPassword,
      permissions: Edit ? editParmission : selectParmission,
      is_active: true,
      menus: menusData,
    };

    e.preventDefault();
    if (Edit) {
      dispatch(editItem(editId, submitdata, axiosRoute));
    } else {
      dispatch(postItem(submitdata, axiosRoute));
    }
    dispatch(searchBarHandlerHide(true));
  };

  const EditHandler = (id) => {
    setEditId(id);
    dispatch(dataEdit());

    let AreaObject = usersData.find((item) => item.id === id);
    setEditValue(AreaObject);
    const editEmployeeId = employeeId.find(
      (item) => item.value === AreaObject.employee_id
    );
    setSelectEmployee(editEmployeeId);
    setInput({
      username: AreaObject.user_name,
    });
    const editTypeId = userType.find((item) => item.value === AreaObject.role);
    setSelectType(editTypeId);
    setMenusData(AreaObject.menus);
    setEditParmission(AreaObject.permissions);

    dispatch(searchBarHandlerHide(false));
  };

  const handleReset = () => {
    setEditValue({});
    setSelectEmployee({});
    setSelectType({});
    setInput({ username: "", password: "", password2: "" });
    setSelectParmission([]);
  };

  const AddHandler = () => {
    dispatch(setShowButton());
    handleReset();
    dispatch(dataAdd());
    setMenusData(menus);
    // dispatch(dataEdit(true))
    dispatch(searchBarHandlerHide(false));
  };
  const GoBack = () => {
    dispatch(dataAdd());

    dispatch(setShowButtonHide());
    dispatch(searchBarHandlerHide(true));
  };

  return (
    <div className="newform">
      <Navbar />
      <Billsidebar />
      <div className="newContainer">
        <div className="top">
          <h1>{Edit ? editTitle : Title}</h1>

          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        <div className="showEntites">
          <span>show </span>
          <select onChange={(event) => setPostPerPage(event.target.value)}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span> entites</span>
        </div>

        {showButton ? (
          <div className="bottom">
            <div className="right">
              <form onSubmit={onSubmitHandler}>
                <div className="form_input">
                  <div className="formInput" style={{ width: "47%" }}>
                    <label>User </label>
                    <Select
                      className="SelectInput"
                      defaultValue={employeeId[0]}
                      value={
                        Object.keys(selectEmployee).length === 0
                          ? employeeId[0]
                          : selectEmployee
                      }
                      onChange={(e) => setSelectEmployee(e)}
                      options={employeeId}
                    />
                  </div>
                  <div className="formInput" style={{ width: "47%" }}>
                    <label>Username</label>
                    {error.username && (
                      <span className="err">{error.username}</span>
                    )}
                    <input
                      type="text"
                      name="username"
                      placeholder="Enter username"
                      value={Edit ? editValue.user_name : input.username}
                      onChange={onInputChange}
                      onBlur={validateInput}
                    />
                  </div>
                  <div className="formInput" style={{ width: "47%" }}>
                    <label>User Type</label>
                    <Select
                      className="SelectInput"
                      value={
                        Object.keys(selectType).length === 0
                          ? userType[0]
                          : selectType
                      }
                      onChange={(e) => setSelectType(e)}
                      defaultValue={userType[0]}
                      options={userType}
                    />
                  </div>

                  {!Edit ? (
                    <>
                      <div className="formInput" style={{ width: "47%" }}>
                        <label>Password</label>
                        {error.password && (
                          <span className="err">{error.password}</span>
                        )}
                        <input
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          value={input.password}
                          onChange={onInputChange}
                          onBlur={validateInput}
                        />
                      </div>
                      <div className="formInput" style={{ width: "47%" }}>
                        <label>Re-Password </label>
                        {error.confirmPassword && (
                          <span className="err">{error.confirmPassword}</span>
                        )}
                        <input
                          type="password"
                          name="confirmPassword"
                          placeholder="Enter Confirm Password"
                          value={input.confirmPassword}
                          onChange={onInputChange}
                          onBlur={validateInput}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <br />
                <div
                  style={{
                    padding: "10px 0",
                    margin: "2rem 0",
                    backgroundColor: "#bebebe55",
                  }}
                >
                  <h2>Setup Role for This User</h2>
                </div>
                <div>
                  <div
                    style={{
                      display: "inline-block",
                      float: "left",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <h2>
                      Hide This Settings Menu{" "}
                      <input
                        type="checkbox"
                        name={"Settings"}
                        checked={isMenu("Settings", "")}
                        onClick={(e) => onChangeHideMenuHandler(e)}
                        // value={menusData[1].is_active}
                      />
                    </h2>
                  </div>
                  <TableContainer component={Paper} className="table">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">SL No.</TableCell>
                          <TableCell className="tableCell">Sub Menu</TableCell>
                          <TableCell className="tableCell">
                            Can Create
                          </TableCell>
                          <TableCell className="tableCell">Can Edit</TableCell>
                          <TableCell className="tableCell">
                            Can Delete
                          </TableCell>
                          <TableCell className="tableCell">Can View</TableCell>
                          <TableCell className="tableCell">Hide</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">1</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Area
                          </TableCell>
                          {area.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Area"}
                              checked={isMenu("Settings", "Area")}
                              // checked={menusData.find((item)=> (item.title === 'Settings' && item.is_active===false))!==undefined?true:false}
                              // checked={
                              //   menusData.find((parentItem)=>(parentItem.title==='Settings')).find((item)=>item.title==='Area' && item.is_active===false)
                              // }

                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Settings")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 2 </TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Branch
                          </TableCell>

                          {branch.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Branch"}
                              checked={isMenu("Settings", "Branch")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Settings")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 3</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Connection Type
                          </TableCell>

                          {connection_type.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Connection Type"}
                              checked={isMenu("Settings", "Connection Type")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Settings")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 4</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Employees
                          </TableCell>

                          {employee.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Employee"}
                              checked={isMenu("Settings", "Employee")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Settings")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 5</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Designation
                          </TableCell>

                          {designation.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Designation"}
                              checked={isMenu("Settings", "Designation")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Settings")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 6</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Account Role
                          </TableCell>

                          {account.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Account Role"}
                              checked={isMenu("Settings", "Account Role")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Settings")
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "inline-block",
                      float: "left",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <h2>
                      Hide This Loan Menu{" "}
                      <input
                        type="checkbox"
                        name={"Loan"}
                        checked={isMenu("Loan")}
                        onClick={(e) => onChangeHideMenuHandler(e)}
                        // value={menusData[1].is_active}
                      />
                    </h2>
                  </div>
                  <TableContainer component={Paper} className="table">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">SL No.</TableCell>
                          <TableCell className="tableCell">Sub Menu</TableCell>
                          <TableCell className="tableCell">
                            Can Create
                          </TableCell>
                          <TableCell className="tableCell">Can Edit</TableCell>
                          <TableCell className="tableCell">
                            Can Delete
                          </TableCell>
                          <TableCell className="tableCell">Can View</TableCell>
                          <TableCell className="tableCell">Hide</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">1</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Loan Giver
                          </TableCell>
                          {loan_giver.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Loan Giver"}
                              checked={isMenu("Loan", "Loan Giver")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Loan")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 2 </TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Loan
                          </TableCell>

                          {loan.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Loan"}
                              checked={isMenu("Loan", "Loan")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Loan")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 3</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Loan Payment
                          </TableCell>

                          {loan_payment.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Loan Payment"}
                              checked={isMenu("Loan", "Loan Payment")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Loan")
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "inline-block",
                      float: "left",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <h2>
                      Hide This Bank Menu{" "}
                      <input
                        type="checkbox"
                        name={"Bank"}
                        checked={isMenu("Bank")}
                        onClick={(e) => onChangeHideMenuHandler(e)}
                        // value={menusData[1].is_active}
                      />
                    </h2>
                  </div>
                  <TableContainer component={Paper} className="table">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">SL No.</TableCell>
                          <TableCell className="tableCell">Sub Menu</TableCell>
                          <TableCell className="tableCell">
                            Can Create
                          </TableCell>
                          <TableCell className="tableCell">Can Edit</TableCell>
                          <TableCell className="tableCell">
                            Can Delete
                          </TableCell>
                          <TableCell className="tableCell">Can View</TableCell>
                          <TableCell className="tableCell">Hide</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">1</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Bank
                          </TableCell>
                          {bank.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Bank"}
                              checked={isMenu("Bank", "Bank")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Bank")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 2 </TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Bank Deposit
                          </TableCell>

                          {bank_deposit.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Bank Deposit"}
                              checked={isMenu("Bank", "Bank Deposit")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Bank")
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "inline-block",
                      float: "left",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <h2>
                      Hide This Bill Menu
                      <input
                        type="checkbox"
                        name={"Bills"}
                        checked={isMenu("Bills")}
                        onClick={(e) => onChangeHideMenuHandler(e)}
                      />
                    </h2>
                  </div>
                  <TableContainer component={Paper} className="table">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">SL No.</TableCell>
                          <TableCell className="tableCell">Sub Menu</TableCell>
                          <TableCell className="tableCell">
                            Can Create
                          </TableCell>
                          <TableCell className="tableCell">Can Edit</TableCell>
                          <TableCell className="tableCell">
                            Can Delete
                          </TableCell>
                          <TableCell className="tableCell">Can View</TableCell>
                          <TableCell className="tableCell">Hide</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">1</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Bill Register
                          </TableCell>
                          {bill_register.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Bill Register"}
                              checked={isMenu("Bills", "Bill Register")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Bills")
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 2 </TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Paid Bill
                          </TableCell>

                          {payment_collection.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Paid Bill"}
                              checked={isMenu("Bills", "Paid Bill")}
                              onClick={(e) =>
                                onChangeHideMenuHandler(e, "Bills")
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: "inline-block",
                      float: "left",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <h2>Hide This Other Menu</h2>
                  </div>
                  <TableContainer component={Paper} className="table">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell">SL No.</TableCell>
                          <TableCell className="tableCell">Sub Menu</TableCell>
                          <TableCell className="tableCell">
                            Can Create
                          </TableCell>
                          <TableCell className="tableCell">Can Edit</TableCell>
                          <TableCell className="tableCell">
                            Can Delete
                          </TableCell>
                          <TableCell className="tableCell">Can View</TableCell>
                          <TableCell className="tableCell">Hide</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableCell"> 1 </TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Customer
                          </TableCell>

                          {customer.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Customer"}
                              checked={isMenu("Customer")}
                              onClick={(e) => onChangeHideMenuHandler(e)}
                              // value={menusData[1].is_active}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell">2</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Employee Salary
                          </TableCell>

                          {employee_salary.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Employee Salary"}
                              checked={isMenu("Employee Salary")}
                              onClick={(e) => onChangeHideMenuHandler(e)}
                              // value={menusData[1].is_active}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell"> 3</TableCell>
                          <TableCell className="tableCell accountTableCell">
                            Expance
                          </TableCell>

                          {expanse.map((item, index) => {
                            let permissionsValue = Edit
                              ? editParmission.includes(item)
                              : selectParmission.includes(item);

                            return (
                              <TableCell key={index} className="tableCell">
                                <input
                                  type="checkbox"
                                  value={item}
                                  checked={permissionsValue}
                                  name={item}
                                  onChange={(e) =>
                                    onChangeHandler(e.target.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <input
                              type="checkbox"
                              name={"Expanse"}
                              checked={isMenu("Expanse")}
                              onClick={(e) => onChangeHideMenuHandler(e)}
                              // value={menusData[1].is_active}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </div>
                <div className="form_button">
                  <button className="button" type="submit">
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => GoBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            {
              <AccountRoleTable
                EditHandler={EditHandler}
                axiosRoute={axiosRoute}
                currentPosts={currentPosts}
                setCount={setCount}
                setPostPerPage={setPostPerPage}
                indexOfFirstPost={indexOfFirstPost}
                indexOfLastPost={indexOfLastPost}
              />
            }
            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={
                  search.trim() === "" ? (usersData ?? data)?.length : count
                }
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountRoleForm;
