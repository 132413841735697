import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Billsidebar from "../billsidebar/BillSidebar";
// import Spiner from "../UI/Spiner";
import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
} from "../../store/allSlice";
import { LoanPayment } from "../../billFormData";
import PaginationButton from "../UI/PaginationButton";

import LoanPaymentTable from "../billTable/LoanPaymentTable";
import Navbar from "../navbar/Navbar";

const LoanPaymentForm = ({ Title, editTitle, axiosRoute }) => {
  let { data, showButton, Edit, search } = useSelector(
    (state) => state.loanpayment
  );
  document.title = "Loan Payment";
  const dispatch = useDispatch();

  const [editId, setEditId] = useState(null);
  const [allData, setAllData] = useState({});
  const [editValue, setEditValue] = useState({});
  const [selectValue, setSelectValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);

  const [count, setCount] = useState(0);

  let loanPaymentData = data?.payment;
  let loansData = data?.loans;
  let loanCalculation = data?.calculation;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = (loanPaymentData ?? data).slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  let key = null;

  const onChangeHandler = (event, navigate) => {
    let { name, value, checked } = event.target;
    if (Edit) {
      let due_amount = 0;
      let loan_amount = 0;
      let amount = 0;
      let given_amount = 0;
      given_amount = due_amount =
        event.target.name === "due_amount"
          ? parseInt(event.target.value)
          : parseInt(editValue.given_amount);
      event.target.name === "due_amount"
        ? parseInt(event.target.value)
        : parseInt(editValue.due_amount);
      loan_amount =
        event.target.name === "loan_amount"
          ? parseInt(event.target.value)
          : parseInt(editValue.loan_amount);
      amount =
        event.target.name === "amount"
          ? parseInt(event.target.value)
          : parseInt(editValue.amount);
      due_amount = loan_amount - given_amount;
      due_amount = due_amount - amount;
      setEditValue({
        ...editValue,
        [name]: event.target.type === "checkbox" ? checked : value,
        [key]: selectValue.value,
        due_amount: due_amount,
        amount: amount,
        loan_amount: loan_amount,
      });
    } else {
      let due_amount = 0;
      let loan_amount = 0;
      let amount = 0;
      let given_amount = 0;
      given_amount = due_amount =
        event.target.name === "due_amount"
          ? parseInt(event.target.value)
          : parseInt(allData.given_amount);
      event.target.name === "due_amount"
        ? parseInt(event.target.value)
        : parseInt(allData.due_amount);
      loan_amount =
        event.target.name === "loan_amount"
          ? parseInt(event.target.value)
          : parseInt(allData.loan_amount);
      amount =
        event.target.name === "amount"
          ? parseInt(event.target.value)
          : parseInt(allData.amount);
      due_amount = loan_amount - given_amount;
      due_amount = due_amount - amount;
      setAllData({
        ...allData,
        [name]: event.target.type === "checkbox" ? checked : value,
        [key]: selectValue.value,
        due_amount: due_amount,
        amount: amount,
        loan_amount: loan_amount,
      });
    }
  };

  const onChangeSelectedValue = (e) => {
    if (Edit) {
      setEditValue({
        ...editValue,
        [key]: e.value,
      });
    } else {
      setAllData({
        ...allData,
        [key]: e.value,
      });
    }
    let loanSelectCalculation = loanCalculation.find(
      (item) => item.id === e.value
    );

    setAllData(loanSelectCalculation);

    return setSelectValue(e);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (Edit) {
      dispatch(editItem(editId, editValue, axiosRoute));
    } else {
      dispatch(postItem(allData, axiosRoute));
    }
    dispatch(searchBarHandlerHide(true));
    handleReset();
  };

  const goBack = () => {
    dispatch(setShowButtonHide());
    dispatch(dataAdd());
    handleReset();
  };

  const EditHandler = (id) => {
    setEditId(id);
    let AreaObject = loanPaymentData.find((item) => item.id === id);
    let PaymentCalculationObject = loanCalculation?.find(
      (item) => item.loan_no === AreaObject["ln_no"]
    );

    setEditValue(Object.assign({}, AreaObject, PaymentCalculationObject));
  };

  const handleReset = () => {
    setAllData({});
    setEditValue({});
  };
  const AddHandler = () => {
    dispatch(setShowButton());
    handleReset();
    dispatch(dataAdd());
  };

  return (
    <div className="newform">
      <Navbar />
      <Billsidebar />

      <div className="newContainer">
        <div className="top">
          <h1>{Edit ? editTitle : Title}</h1>

          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        <div className="showEntites">
          <span>show </span>
          <select onChange={(event) => setPostPerPage(event.target.value)}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span> entites</span>
        </div>

        {showButton ? (
          <div className="bottom">
            <div className="right">
              <form id="clear_data" onSubmit={onSubmitHandler}>
                <div className="form_input">
                  {LoanPayment.map((input, index) => {
                    if (input.type === "select") {
                      key = input.db_name;
                      const lgSelectData = loansData?.find(
                        (item) => item.value === editValue[input.db_name]
                      );

                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <Select
                            className="search_dropdown"
                            name={input.db_name}
                            value={
                              // Error here
                              Edit && Object.keys(selectValue).length === 0
                                ? lgSelectData ?? loansData[0]
                                : Object.keys(selectValue).length === 0
                                ? loansData[0]
                                : selectValue
                            }
                            defaultValue={selectValue}
                            onChange={(e) => onChangeSelectedValue(e)}
                            options={loansData}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>

                          <input
                            className={
                              input.readOnly ? "input_readOnly" : "input"
                            }
                            autoComplete="off"
                            value={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                              // Edit && input.type === "date"
                              //   ? editValue[input.db_name]
                              //   : allData[input.db_name] ??
                              //     (Edit
                              //       ? editValue[input.db_name] ||
                              //         (loanCalculateData === undefined ||
                              //         Object.keys(selectValue).length === 0
                              //           ? loanCalculateEditData[input.db_name]
                              //           : loanCalculateData[input.db_name])
                              //       : allData[input.db_name] ||
                              //         (loanCalculateData === undefined ||
                              //         Object.keys(selectValue).length === 0
                              //           ? loanCalculateEditData[input.db_name]
                              //           : loanCalculateData[input.db_name]))
                            }
                            onChange={onChangeHandler}
                            name={input.db_name}
                            checked={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                            }
                            type={input.type}
                            placeholder={input.placeholder}
                            required={input.required}
                            readOnly={input.readOnly}
                            hidden={input.hidden}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="form_button">
                  <button className="button" type="submit">
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => goBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            <LoanPaymentTable
              EditHandler={EditHandler}
              axiosRoute={axiosRoute}
              currentPosts={currentPosts}
              setCount={setCount}
              setPostPerPage={setPostPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
            />

            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={
                  search.trim() === "" ? loanPaymentData?.length : count
                }
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default LoanPaymentForm;
