import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchItem,
  dataEdit,
  setShowButton,
  deleteItem,
} from "../../store/allSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Alert from "../UI/Alert";
import Spiner from "../UI/Spiner";

const AccountRoleTable = ({
  EditHandler,
  axiosRoute,
  currentPosts,
  setCount,
  indexOfFirstPost,
  indexOfLastPost,
}) => {
  let keys = ["user_name", "description"];
  let { data, search, isLoading } = useSelector((state) => state.area);
  const users = data?.users;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [indexId, setIndexId] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
  }, [dispatch]);

  const areaDeleteHandler = (id) => {
    dispatch(deleteItem(id, axiosRoute));

    setOpen(false);
  };
  const model = () => {
    setOpen((prevState) => !prevState);
  };
  const editHandler = (id) => {
    EditHandler(id);
    dispatch(dataEdit());
    dispatch(setShowButton());
  };
  function renderItemFunction() {
    let searchItem;
    function dataSearch() {
      let searchArea = users.filter((row) =>
        keys.some((key) => {
          if (row[key] === null || row[key] === undefined) {
            searchItem = "";
          } else {
            searchItem = row[key]
              .toLowerCase()
              .includes(search.trim().toLowerCase());
          }
          return searchItem;
        })
      );
      let searchAreaData = searchArea.slice(indexOfFirstPost, indexOfLastPost);

      setCount(searchArea.length);
      return searchAreaData;
    }

    return (search.trim() === "" ? currentPosts : dataSearch()).map((row) => {
      return (
        <TableRow key={row.id}>
          <TableCell className="tableCell">{row.user_name}</TableCell>
          <TableCell className="tableCell">{row.designation}</TableCell>
          <TableCell className="tableCell">{row.email}</TableCell>
          <TableCell className="tableCell">{row.branch}</TableCell>

          <TableCell className="tableCell">
            <div className="group_button">
              <button
                onClick={() => editHandler(row.id)}
                className="button_table"
                aria-label="delete"
              >
                <ModeEditIcon className="edit_icon" />
              </button>
              <button
                onClick={() => {
                  model();
                  setIndexId(row.id);
                  setTitle(row.user_name);
                }}
                className="button_table"
                aria-label="delete"
              >
                <DeleteOutlineIcon className="delete_icon" />
              </button>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">User Name </TableCell>
                <TableCell className="tableCell">Designation</TableCell>
                <TableCell className="tableCell">Email </TableCell>
                <TableCell className="tableCell">Branch </TableCell>
                <TableCell className="tableCell_actions">কর্ম </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderItemFunction()}</TableBody>
          </Table>
          {open && (
            <Alert
              areaDeleteHandler={areaDeleteHandler}
              open={open}
              model={model}
              title={title}
              id={indexId}
            />
          )}
        </TableContainer>
      )}
    </>
  );
};

export default AccountRoleTable;
