import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import Table from "@mui/material/Table";
import PrintIcon from "@mui/icons-material/Print";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Navbar from "../navbar/Navbar";
import BillSidebar from "../billsidebar/BillSidebar";

const SubMeterInvoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "SubMeter Report",
    content: () => componentRef.current,
  });
  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="print_grid" style={{ width: "92%" }}>
          <div className="center_invoice-submeter">
            <div className="body" ref={componentRef}>
              <div>
                <div
                  id="main-section-submeter"
                  // media="print"
                >
                  <TableContainer component={Paper} className="table">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={8}>
                            <div
                              id="top-section1"
                              style={{ textAlign: "center" }}
                            >
                              <p>বিসমিল্লাহির রাহমানির রাহিম</p>
                              <p>
                                "বিদ্যুৎ জাতীয় সম্পদ অপচয় রোধে এগিয়ে আসুন,অবৈধ
                                সংযোগ থেকে বিরত থাকুন"
                              </p>
                              <h4>
                                চট্টগ্রাম মহানগর ছিন্নমুল বস্তিবাসি সমন্বয়
                                সংগ্রাম পরিষদ
                              </h4>
                              <p>
                                জঙ্গল ছলিমপুর ছিন্নমুল পূনর্বাসন প্রকল্প, ডাকঘরঃ
                                জাফরাবাদ,থানাঃসিতাকুন্ড,চট্টগ্রাম |
                              </p>
                              <p>বিদ্যুৎ বিতরন বিভাগ</p>
                              <h2 style={{ padding: "0" }}>
                                সাব মিটার রিপোর্ট{" "}
                              </h2>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell">গ্রাহক নং</TableCell>
                          <TableCell className="tableCell">
                            গ্রাহকের নাম
                          </TableCell>
                          <TableCell className="tableCell">
                            পিতা/স্বামী নাম
                          </TableCell>
                          <TableCell className="tableCell">
                            গ্রাহক লিঙ্গ
                          </TableCell>
                          <TableCell className="tableCell">ঠিকানা</TableCell>
                          <TableCell className="tableCell">মোবাইল নং</TableCell>
                          <TableCell className="tableCell">
                            সংযোগের ধরন
                          </TableCell>
                          <TableCell className="tableCell">মিটার নং</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        {state?.map((item) => {
                          return (
                            <TableRow key={item.meter_no}>
                              <TableCell className="tableCell">
                                {item.customer_no}
                              </TableCell>
                              <TableCell className="tableCell">
                                {item.customer_name}
                              </TableCell>
                              <TableCell className="tableCell">
                                {item.guardian_name}
                              </TableCell>
                              <TableCell className="tableCell">
                                {item.gender}
                              </TableCell>
                              <TableCell className="tableCell">
                                {item.address}
                              </TableCell>
                              <TableCell className="tableCell">
                                {item.mobile_no}
                              </TableCell>
                              <TableCell className="tableCell">
                                {item.connection}
                              </TableCell>
                              <TableCell className="tableCell">
                                {item.meter_no}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableHead>
                    </Table>
                  </TableContainer>
                  {/* <!-- BOTTOM div END -->
    <!-- CUSTOMER SECTON START --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="print_option_submeter">
          <div
            className="fixed_button_submeter"
            style={{ width: "15%", right: "2rem" }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <button
                onClick={() => {
                  handlePrint();
                }}
              >
                <PrintIcon
                  style={{
                    fill: "#fff",
                    color: "#fff",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                />
              </button>
              <button
                onClick={() => navigate("/admin/report/sub-meter-report")}
              >
                <ReplyAllIcon
                  style={{
                    fill: "#fff",
                    color: "#fff",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubMeterInvoice;
