import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchItem } from "../../store/userSlice";
import { useNavigate } from "react-router-dom";
import logo from "./login_logo.png";

const CustomerLogin = ({}) => {
  const navigate = useNavigate();
  let time;

  const dispatch = useDispatch();
  const [input, setInput] = useState({
    customer: "",
    meter: "",
  });

  const [error, setError] = useState({
    customer: "",
    meter: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "customer":
          if (!value) {
            stateObj[name] = "Please enter customer No: xxxx";
          }
          break;

        case "meter":
          if (!value) {
            stateObj[name] = "Please enter Meter No: xxxxxxx";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();

    if (input.customer !== "" && input.meter !== "") {
      let user = `user-login?meter_no=${input.meter}&customer_no=${input.customer}`;
      dispatch(fetchItem(user));
      time = setTimeout(() => {
        navigate("/user");
      }, 1000);
      return;
    }

    // } else {

    // }
  };
  clearTimeout(time);
  // user-login?meter_no=6908&customer_no=6908
  return (
    <div className="loginMainContainer">
      <br />
      <br />
      <h1 style={{ textAlign: "center", color: "white", fontSize: "5vw" }}>
        স্বাগতম ছিন্নমূল বিদ্যুৎ বিতরন বিভাগ{" "}
      </h1>
      <br />

      <div className="loginContainer">
        <div className="loginItem">
          <div className="circleLogo">
            <img width={"100px"} height={"100px"} src={logo} alt="" />
          </div>
          <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
            গ্রাহক লগইন
          </h2>
        </div>
        <form onSubmit={SubmitHandler}>
          <div className="loginInputContainer">
            <div>
              <label>Account Number</label>
              <input
                placeholder="Account Number"
                className="loginInput"
                type="text"
                value={input.customer}
                onChange={onInputChange}
                onBlur={validateInput}
                name="customer"
              ></input>
              {error.customer && <span>{error.customer}</span>}
            </div>
            <div style={{ marginTop: "15px" }}>
              <label>Meter Number</label>
              <input
                placeholder="Meter Number"
                className="loginInput"
                type="text"
                value={input.meter}
                onChange={onInputChange}
                onBlur={validateInput}
                name="meter"
              ></input>
              {error.meter && <span>{error.meter}</span>}
            </div>
            <button type="submit" className="loginButton">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerLogin;
