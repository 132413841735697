import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Billsidebar from "../billsidebar/BillSidebar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import PaginationButton from "../UI/PaginationButton";
import Select from "react-select";
import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
} from "../../store/allSlice";
import { BankDepositDataForm } from "../../billFormData";
import Navbar from "../navbar/Navbar";
import BankDepositTable from "../billTable/BankDepositTable";

const BankDepositForm = ({ Title, editTitle, axiosRoute }) => {
  document.title = "Bank Deposit";
  const dispatch = useDispatch();
  const [editId, setEditId] = useState(null);
  const [allData, setAllData] = useState({});
  const [editValue, setEditValue] = useState({});
  const [result, setResult] = useState({});

  const [selectValue, setSelectValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [count, setCount] = useState(0);

  let { data, showButton, Edit, search } = useSelector(
    (state) => state.bank_deposit
  );
  let BankSearchData = data?.search_banks;
  let ResultData = data?.result;
  let BankDepositData = data?.bank_deposit;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = (BankDepositData ?? data)?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const onChangeHandler = (event) => {
    let { name, value, checked } = event.target;

    if (Edit) {
      let balance = 0,
        deposit_amount = 0,
        closing_balance = 0;
      balance =
        event.target.name === "balance"
          ? parseInt(event.target.value)
          : parseInt(editValue.balance);
      deposit_amount =
        event.target.name === "deposit_amount"
          ? parseInt(event.target.value)
          : parseInt(editValue.deposit_amount);
      closing_balance =
        event.target.name === "closing_balance"
          ? parseInt(event.target.value)
          : parseInt(editValue.closing_balance);

      closing_balance = balance - deposit_amount;

      setEditValue({
        ...editValue,
        [name]: event.target.type === "checkbox" ? checked : value,
        closing_balance: closing_balance,
        balance: balance,
        deposit_amount: deposit_amount,
      });
    } else {
      let balance = 0,
        deposit_amount = 0,
        closing_balance = 0;

      balance =
        event.target.name === "balance"
          ? parseInt(event.target.value)
          : parseInt(result.balance);
      deposit_amount =
        event.target.name === "deposit_amount"
          ? parseInt(event.target.value)
          : parseInt(allData.deposit_amount);
      closing_balance =
        event.target.name === "closing_balance"
          ? parseInt(event.target.value)
          : parseInt(allData.closing_balance);

      closing_balance = balance - deposit_amount;

      setAllData({
        ...allData,
        [name]: event.target.type === "checkbox" ? checked : value,
        closing_balance: closing_balance,
        balance: balance,
        deposit_amount: deposit_amount,
      });
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (Edit) {
      dispatch(editItem(editId, editValue, axiosRoute));
    } else {
      dispatch(postItem(allData, axiosRoute));
    }
  };
  const onChangeSelectedValue = (e) => {
    if (Edit) {
      setEditValue({
        ...editValue,
        bank_id: e.value,
      });
    } else {
      setAllData({
        ...allData,
        bank_id: e.value,
      });
    }
    dispatch(searchBarHandlerHide(true));
    return setSelectValue(e);
  };

  const EditHandler = (id) => {
    setEditId(id);
    let employeeSData = BankDepositData?.find((item) => item.id === id);

    setEditValue(employeeSData);
    dispatch(searchBarHandlerHide(false));
  };
  const handleReset = () => {
    setAllData({});
    setEditValue({});
  };

  const AddHandler = () => {
    let obj = Object.assign({}, ResultData[0]);
    setResult(obj);
    dispatch(setShowButton());
    handleReset();
    dispatch(dataAdd());
    dispatch(searchBarHandlerHide(false));
  };
  const GoBack = () => {
    dispatch(dataAdd());
    dispatch(setShowButtonHide());
    dispatch(searchBarHandlerHide(true));
  };
  return (
    <div className="newform">
      <Navbar />
      <Billsidebar />
      <div className="newContainer">
        <div className="top">
          <h1>{Edit ? editTitle : Title}</h1>
          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        <div className="showEntites">
          <span>show </span>
          <select onChange={(event) => setPostPerPage(event.target.value)}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span> entites</span>
        </div>
        {showButton ? (
          <div className="bottom">
            <div className="right">
              <form id="clear_data" onSubmit={onSubmitHandler}>
                <div className="form_input flexContentNone">
                  {BankDepositDataForm.map((input, index) => {
                    if (input.type === "select") {
                      const BankSelectedData = BankSearchData.find(
                        (item) => item.value === editValue[input.db_name]
                      );
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <Select
                            className="search_dropdown"
                            name={input.db_name}
                            value={
                              Edit && Object.keys(selectValue).length === 0
                                ? BankSelectedData ?? BankSearchData[0]
                                : Object.keys(selectValue).length === 0
                                ? BankSearchData[0]
                                : selectValue
                            }
                            defaultValue={BankSearchData}
                            onChange={(e) => onChangeSelectedValue(e)}
                            options={BankSearchData}
                          />
                        </div>
                      );
                    } else {
                      // let resultDate = result?.date
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <input
                            className={
                              input.readOnly ? "input_readOnly" : "input"
                            }
                            autoComplete="off"
                            value={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name] ??
                                  result[input.db_name]
                            }
                            onChange={onChangeHandler}
                            name={input.db_name}
                            checked={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                            }
                            type={input.type}
                            placeholder={input.placeholder}
                            required={input.required}
                            readOnly={input.readOnly}
                            hidden={input.hidden}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="form_button">
                  <button className="button" type="submit">
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => GoBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            <BankDepositTable
              EditHandler={EditHandler}
              axiosRoute={axiosRoute}
              currentPosts={currentPosts}
              setCount={setCount}
              setPostPerPage={setPostPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
            />

            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={
                  search.trim() === "" ? BankDepositData?.length : count
                }
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default BankDepositForm;
