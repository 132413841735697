import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BillCollectionTable from "./../billTable/BillCollectionTable";

import {
  postItem,
  editItem,
  fetchItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
} from "../../store/allSlice";
import { PaymentCollection } from "../../billFormData";
import PaginationButton from "../UI/PaginationButton";
import BillSidebar from "../billsidebar/BillSidebar";
import Navbar from "../navbar/Navbar";

const BillRegisterForm = ({ Title, axiosRoute, editTitle }) => {
  let { data, showButton, Edit, field_error, search } = useSelector(
    (state) => state.billregister
  );
  document.title = "Bill Collection/paid";
  // let { searchData } = useSelector((state) => state.searchCustomer);
  const dispatch = useDispatch();

  const [editId, setEditId] = useState(null);
  const [allData, setAllData] = useState({});
  const [editValue, setEditValue] = useState({});
  const [selectValue, setSelectValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [fixdate, setFixDate] = useState({});
  const [count, setCount] = useState(0);
  const [month, setMonth] = useState("");

  let bill_search_data = data?.search_customer;
  let billData = data?.payment;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = (billData ?? data).slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  let key = null;

  const onChangeHandler = (event) => {
    let { name, value, checked } = event.target;
    if (Edit) {
      let payment_amount = 0,
        discount_amount = 0,
        due_deposit = 0;
      if (
        event.target.name === "amount" ||
        event.target.name === "discount_amount"
      ) {
        payment_amount =
          event.target.name === "amount"
            ? parseInt(event.target.value)
            : parseInt(editValue["amount"]) ?? 0;
        if (isNaN(payment_amount)) {
          payment_amount = 0;
        }
        discount_amount =
          event.target.name === "discount_amount"
            ? parseInt(event.target.value)
            : parseInt(editValue["discount_amount"]) ?? 0;
        if (isNaN(discount_amount)) {
          discount_amount = 0;
        }
        due_deposit =
          parseInt(editValue["bill_amount"]) -
          (payment_amount + discount_amount);

        setEditValue({
          ...editValue,
          [name]: event.target.type === "checkbox" ? checked : value,
          // [key]: selectValue.value,
          bill_id: editValue.bill_id,
          bill_due: due_deposit,
          due_deposit: due_deposit,
        });
      }
    } else {
      let payment_amount = 0,
        discount_amount = 0,
        due_deposit = 0;
      if (
        event.target.name === "amount" ||
        event.target.name === "discount_amount"
      ) {
        payment_amount =
          event.target.name === "amount"
            ? parseInt(event.target.value)
            : parseInt(allData["amount"]) || 0;

        discount_amount =
          event.target.name === "discount_amount"
            ? parseInt(event.target.value)
            : parseInt(allData["discount_amount"]) || 0;
        due_deposit =
          parseInt(allData["bill_amount"]) - (payment_amount + discount_amount);
      }
      setAllData({
        ...allData,
        [name]: event.target.type === "checkbox" ? checked : value,
        // [key]: selectValue.value,
        amount: payment_amount,
        discount_amount: discount_amount,
        due_deposit: due_deposit,
      });
    }
  };

  const onChangeSelectedValue = (e) => {
    if (Edit) {
      setEditValue({
        ...editValue,
        [key]: e.value,
      });
    } else {
      setAllData(e);
    }
    return setSelectValue(e);
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (Edit) {
      dispatch(editItem(editId, editValue, axiosRoute));
    } else {
      dispatch(postItem(allData, axiosRoute));
    }
    setTimeout(() => {
      document.getElementById("clear").click();
    }, 400);
    handleReset();
  };
  const goBack = () => {
    dispatch(setShowButtonHide());
    dispatch(dataAdd());
    handleReset();
    dispatch(searchBarHandlerHide(true));
  };

  const EditHandler = (id) => {
    setEditId(id);

    // let billDataCopy = { ...billData.find((item) => item.id === id) };
    // billDataCopy.total_bill = billDataCopy?.net_bill;
    // setEditValue(billDataCopy);

    dispatch(searchBarHandlerHide(false));
  };

  const handleReset = () => {
    setEditValue({});
    setFixDate({});
    submitReset();
  };
  const submitReset = () => {
    setSelectValue({});
    setAllData({});
    setEditValue({});
  };
  const AddHandler = () => {
    dispatch(setShowButton());
    submitReset();
    dispatch(dataAdd());
    dispatch(searchBarHandlerHide(false));
  };
  const monthHandler = () => {
    if (month === "" || month === undefined) {
      return;
    } else {
      let filterMonth = `payment-collection/?form_month=${month}`;
      dispatch(fetchItem(filterMonth));
    }
  };
  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />

      <div className="newContainer">
        <div className="top">
          <h1>{showButton ? editTitle : Title}</h1>

          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        {!showButton ? (
          <div className="showEntites" style={{ display: "flex" }}>
            <div style={{ width: "35%" }}>
              <span>show </span>
              <select onChange={(event) => setPostPerPage(event.target.value)}>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
              <span> entites</span>
            </div>

            <div className="middleDate" style={{ display: "inline-flex" }}>
              <label style={{ marginRight: "10px" }}>বিলের মাস :</label>{" "}
              <input
                type="month"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              />
              <button
                onClick={monthHandler}
                style={{ margin: "0 10px", width: "150px" }}
              >
                Search
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        {showButton ? (
          <div className="bottom">
            <div className="right">
              <form id="clear_data" onSubmit={onSubmitHandler}>
                <div className="form_input">
                  {PaymentCollection.map((input, index) => {
                    if (input.type === "select") {
                      key = input.db_name;
                      const lgSelectData = bill_search_data?.find(
                        (item) => item.value === editValue[input.db_name]
                      );
                      let optionData = Edit ? selectValue : bill_search_data;
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <Select
                            className="search_dropdown"
                            style={{
                              border:
                                input.db_name === field_error
                                  ? "1px solid red"
                                  : null,
                            }}
                            name={input.db_name}
                            value={
                              Edit && Object.keys(selectValue).length === 0
                                ? lgSelectData ?? bill_search_data
                                : Object.keys(selectValue).length === 0
                                ? bill_search_data[0]
                                : selectValue
                            }
                            defaultValue={selectValue}
                            onChange={(e) => onChangeSelectedValue(e)}
                            options={optionData}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <input
                            className={
                              input.readOnly ? "input_readOnly" : "input"
                            }
                            style={{
                              border:
                                input.db_name === field_error
                                  ? "1px solid red"
                                  : null,
                            }}
                            autoComplete="off"
                            value={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name] ??
                                  fixdate[input.db_name]
                            }
                            onChange={onChangeHandler}
                            name={input.db_name}
                            checked={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                            }
                            type={input.type}
                            placeholder={input.placeholder}
                            required={input.required}
                            readOnly={input.readOnly}
                            hidden={input.hidden}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="form_button">
                  <button>
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => goBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            <BillCollectionTable
              setSelectValue={setSelectValue}
              setEditValue={setEditValue}
              EditHandler={EditHandler}
              axiosRoute={axiosRoute}
              currentPosts={currentPosts}
              setCount={setCount}
              setPostPerPage={setPostPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
            />

            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={search.trim() === "" ? billData?.length : count}
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default BillRegisterForm;
