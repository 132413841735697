import { configureStore } from "@reduxjs/toolkit";
import allSlice from "./allSlice";
import customerSearchSlice from "./customerSearchSlice";
import userSlice from "./userSlice";

const reducer = {
  user: userSlice,
  searchCustomer: customerSearchSlice,
  area: allSlice,
  customer: allSlice,
  branch: allSlice,
  employee: allSlice,
  bank: allSlice,
  bank_deposit: allSlice,
  connection: allSlice,
  designation: allSlice,
  loangiver: allSlice,
  loan: allSlice,
  loanpayment: allSlice,
  loansearch: allSlice,
  billregister: allSlice,
  expance: allSlice,
  allinvoice: allSlice,
  billCollection: allSlice,
  accountRole: allSlice,
  billreport: allSlice,
};
const store = configureStore({
  reducer: reducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
