import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const Refresh = ({ setIsAuthenicated }) => {
  const location = useLocation();
  const navigate = useNavigate();

  let access_token;
  // let refresh_token;
  useEffect(() => {
    if (localStorage.getItem("token")) {
      access_token = JSON.parse(localStorage.getItem("token") ?? "");
      // refresh_token = JSON.parse(localStorage.getItem("token") ?? "");

      if (!access_token.refresh && !access_token.access) {
        setIsAuthenicated(false);
        return navigate("/", { replace: true });
      } else if (access_token.refresh && access_token.access) {
        setIsAuthenicated(true);
        if (
          location.pathname === "/admin/login" ||
          location.pathname === "/admin/login/" ||
          location.pathname === "/admin" ||
          location.pathname === "/admin/"
        ) {
          return navigate("/admin/dashboard", { replace: true });
        }
      }
    }
  }, [location, navigate, setIsAuthenicated]);
  return null;
};
export default Refresh;
