// import "./login.scss";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
const PrivateRoute = () => {
  let access_token;

  if (localStorage.getItem("token")) {
    access_token = JSON.parse(localStorage.getItem("token") ?? "");
  }

  if (!access_token) return <Navigate to="/" />;

  return <Outlet />;
};
export default PrivateRoute;
