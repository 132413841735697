import React, { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import PrintIcon from "@mui/icons-material/Print";
import Navbar from "../navbar/Navbar";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import BillSidebar from "../billsidebar/BillSidebar";
import Spiner from "../UI/Spiner";

import {
  DigitConvert,
  CustomDayMonthYear,
  CustomYearMonth,
} from "../../store/customDate";

const PrintSingleInvoice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  document.title = `single invoice ${state.length} page`;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="print_grid">
          <div className="center_invoice">
            <div
              className="body"
              ref={componentRef}
              style={{ backgroundColor: "#d8d6d6" }}
            >
              {Object.keys(state).length === 0 ? (
                <Spiner />
              ) : (
                state?.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <div id="main-section" media="print">
                        {/* <!-- TOP div START --> */}
                        <div id="top-section">
                          <p>বিসমিল্লাহির রাহমানির রাহিম</p>
                          <p>
                            "বিদ্যুৎ জাতীয় সম্পদ অপচয় রোধে এগিয়ে আসুন,অবৈধ সংযোগ
                            থেকে বিরত থাকুন"
                          </p>
                          <h4>
                            চট্টগ্রাম মহানগর ছিন্নমুল বস্তিবাসি সমন্বয় সংগ্রাম
                            পরিষদ
                          </h4>
                          <p>
                            জঙ্গল ছলিমপুর ছিন্নমুল পূনর্বাসন প্রকল্প, ডাকঘরঃ
                            জাফরাবাদ,থানাঃসিতাকুন্ড,চট্টগ্রাম |
                          </p>
                          <p>বিদ্যুৎ বিতরন বিভাগ</p>
                          <div className="power-bill">বিদ্যুৎ বিল</div>
                        </div>
                        {/* <!-- TOP div END -->
      <!-- BOTTOM div SATRT --> */}
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr style={{ border: "none" }}>
                              <td style={{ border: "none" }}>
                                ক্রমিক নং:{" "}
                                <strong>{DigitConvert(item.invoice_no)}</strong>
                                <br />
                                এরিয়া কোড/শাখা :{" "}
                                <strong>
                                  {item.branch},{item.address}
                                </strong>
                              </td>
                              <td
                                style={{ border: "none", textAlign: "right" }}
                              >
                                {" "}
                                <strong>( গ্রাহক কপি )</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* <!-- BOTTOM div END -->
      <!-- CUSTOMER SECTON START --> */}
                        <div id="customer-section">
                          {/* <!-- CUSTOMER div TOP START --> */}
                          <table
                            style={{ width: "100%" }}
                            className="customer-info-top-table"
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: "20%" }}>
                                  হিসাব নং/গ্রাহক নং
                                  <br />
                                  গ্রাহকের নাম <br />
                                  পিতা /স্বামীর নাম
                                  <br />
                                  ঠিকানা
                                  <br />
                                  মিটার নং
                                  <br />
                                </td>
                                <td
                                  style={{
                                    width: "2%",
                                    borderLeftStyle: "hidden",
                                    borderRightStyle: "hidden",
                                  }}
                                >
                                  :<br />:<br />:<br />:<br />:
                                </td>
                                <td>
                                  <strong>
                                    {DigitConvert(item.customer_no)}
                                  </strong>
                                  <br />
                                  <strong>{item.customer_name}</strong>
                                  <br />
                                  <strong>{item.guardian_name}</strong>
                                  <br />
                                  <strong>{item.branch}</strong>
                                  <br />
                                  <strong>{DigitConvert(item.meter_no)}</strong>
                                </td>
                                <td style={{ width: "26%" }}>
                                  বিলের মাস <br />
                                  বিল প্রস্তুতের তারিখ
                                  <br />
                                  পরিশোধের শেষ তারিখ
                                  <br />
                                  <br />
                                  <br />
                                </td>
                                <td
                                  style={{
                                    width: "20%",
                                    borderLeftStyle: "hidden",
                                  }}
                                >
                                  <strong>{CustomYearMonth(item.month)}</strong>
                                  <br />
                                  <strong>
                                    {CustomDayMonthYear(item.created_date)}
                                  </strong>
                                  <br />
                                  <strong>
                                    {CustomDayMonthYear(item.last_paid_date)}
                                  </strong>
                                  <br />
                                  <br />
                                  <br />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            style={{ width: "100%", borderTopStyle: "hidden" }}
                            className="customer-info-center-table"
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{ width: "17%", textAlign: "center" }}
                                >
                                  আগের বিলের মাস
                                </td>
                                <td
                                  style={{ width: "17%", textAlign: "center" }}
                                >
                                  ব্যবহৃত ইউনিট
                                </td>
                                <td
                                  style={{ width: "17%", textAlign: "center" }}
                                >
                                  বিল
                                </td>
                                <td
                                  style={{ width: "17%", textAlign: "center" }}
                                >
                                  পরিশোধিত বিল{" "}
                                </td>
                                <td
                                  style={{ width: "17%", textAlign: "center" }}
                                >
                                  ছাড়
                                </td>
                                <td
                                  style={{ width: "15%", textAlign: "center" }}
                                >
                                  বকেয়া
                                </td>
                              </tr>

                              {item?.previous_data
                                ?.slice(0, 2)
                                .map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          width: "20%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.created_date}
                                        {/* {CustomYearMonth(item.created_date)} */}
                                      </td>
                                      <td
                                        style={{
                                          width: "17%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.previous_unit_bn}
                                        {/* {DigitConvert(item.uses_unit)} */}
                                      </td>
                                      <td
                                        style={{
                                          width: "17%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.bill_amount_bn}

                                        {/* {DigitConvert(item.net_bill)} */}
                                      </td>
                                      <td
                                        style={{
                                          width: "17%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.payment_amount_bn}
                                        {/* {DigitConvert(item.paid_amount)} */}
                                      </td>
                                      <td
                                        style={{
                                          width: "17%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.discount_bn}
                                        {/* {DigitConvert(item.discount)} */}
                                      </td>
                                      <td
                                        style={{
                                          width: "17%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item.due_bill_bn}
                                        {/* {DigitConvert(item.due_bill)} */}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          {/* <!-- CUSTOMER div TOP END -->
          <!-- CUSTOMER div BOOTOM START --> */}
                          <table
                            style={{ width: "100%", borderTopStyle: "hidden" }}
                            className="customer-info-center-table"
                          >
                            <tbody>
                              <tr>
                                <td
                                  colSpan="3"
                                  style={{ width: "40%", textAlign: "center" }}
                                >
                                  মিটার রিডিং
                                </td>
                                <td colSpan="2">বিবরণ</td>
                                <td
                                  style={{
                                    width: "16%",
                                    borderLeftStyle: "hidden",
                                    paddingRight: "8px",
                                    textAlign: "right",
                                  }}
                                >
                                  টাকা
                                </td>
                              </tr>

                              <tr>
                                <td colSpan="2" style={{ textAlign: "center" }}>
                                  তারিখ
                                </td>
                                <td style={{ width: "50px" }}>ইউনিট</td>
                                <td rowSpan="4">
                                  ব্যবহৃত ইউনিট মূল্য
                                  <br />
                                  লাইন চার্জ
                                  <br />
                                  সার্ভিস চার্জ
                                  <br />
                                  ডিমান্ড চার্জ
                                  <br />
                                  বকেয়া বিল
                                  <br />
                                  বকেয়া বিলের জরিমানা
                                  <br />
                                  অতিরিক্ত চার্জ
                                  <br />
                                  বিদ্যুৎ শুল্ক(%)
                                </td>
                                <td
                                  rowSpan="4"
                                  style={{
                                    textAlign: "left",
                                    borderLeftStyle: "hidden",
                                    borderRightStyle: "hidden",
                                    width: "8px",
                                  }}
                                >
                                  : <br />: <br />: <br />: <br />: <br />:{" "}
                                  <br />
                                  : <br />:
                                </td>
                                <td
                                  rowSpan="4"
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "7px",
                                  }}
                                >
                                  {DigitConvert(item.usage_unit_price)} <br />
                                  {DigitConvert(item.line_charge)} <br />
                                  {DigitConvert(item.service_charge)} <br />
                                  {DigitConvert(item.demand_charge)} <br />
                                  {DigitConvert(item.due_bill)}
                                  <br />
                                  {DigitConvert(item.late_fee)}
                                  <br />
                                  {DigitConvert(item.extra_charge)}
                                  <br />
                                  {DigitConvert(item.tax)}
                                </td>
                              </tr>
                              <tr>
                                <td>বর্তমান</td>
                                <td>{CustomDayMonthYear(item.created_date)}</td>
                                <td>{DigitConvert(item.current_unit)}</td>
                              </tr>
                              <tr>
                                <td>পূর্ববতী</td>
                                <td>
                                  {item.previous_data
                                    ?.slice(0, 1)
                                    .map((itm) => itm.created_date)}
                                </td>
                                <td>{DigitConvert(item.previous_unit)}</td>
                              </tr>
                              <tr>
                                <td colSpan="2">
                                  ব্যবহৃত ইউনিট
                                  <br />
                                  {/* <strong>বিদ্যুতের দর:ইউনিট=টাকা</strong> */}
                                </td>
                                <td>
                                  {DigitConvert(item.uses_unit)}
                                  <br />
                                  {/* <strong>{item.unit_price_bn}</strong> */}
                                </td>
                              </tr>
                              <tr>
                                <td rowSpan="2" colSpan="3">
                                  অতিরিক্ত চার্জের বিবরন: <br />
                                  {/* <strong>বিবরন বিবরন বিবরন</strong> */}
                                  <strong>
                                    {item.extra_charge_description}
                                  </strong>
                                </td>
                                <td rowSpan="2">
                                  <strong>মোট বিল</strong>
                                  <br />
                                  ছাড়(-)
                                  <br />
                                  <strong>
                                    <hr />
                                    বিল
                                  </strong>
                                </td>
                                <td
                                  rowSpan="2"
                                  style={{
                                    textAlign: "left",
                                    borderLeftStyle: "hidden",
                                    borderRightStyle: "hidden",
                                  }}
                                >
                                  <strong>:</strong>
                                  <br />: <br />
                                  <hr />
                                  <strong>:</strong>
                                </td>
                                <td
                                  rowSpan="2"
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "7px",
                                  }}
                                >
                                  {/* <br /> */}
                                  <strong>
                                    {DigitConvert(item.total_bill)}
                                  </strong>

                                  <br />
                                  {DigitConvert(item.discount)}
                                  {/* <br /> */}
                                  <hr />

                                  <strong>
                                    ৳ {DigitConvert(item.net_bill)}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            style={{ width: "100%", borderTopStyle: "hidden" }}
                            className="customer-info-bottom-table"
                          >
                            <tbody>
                              <tr style={{ borderBottomStyle: "hidden" }}>
                                <td>
                                  {/* <br /> */}
                                  {item.bill_created}
                                  <br />
                                  বিল প্রস্তুতকারী
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    borderLeftStyle: "hidden",
                                  }}
                                >
                                  <br />
                                  বিল ইস্যুকারী
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2">
                                  <br />
                                  <strong>নির্দশনা</strong>
                                  <br />
                                  &#10148; উপরোক্ত বিলের টাকা নির্দিষ্ট তারিখের
                                  মধ্যে কর্তৃপক্ষের বরাবরে পরিশোধ করিতে হইবে |
                                  <br />
                                  &#10148; সাইড লাইন ব্যবহার করা যাবে না |
                                  <br />
                                  &#10148; নির্ধারিত তারিখের মধ্যে বিল পরিশোধ না
                                  করিলে সংযোগ বিচ্ছিন্ন করা হবে |
                                  <br />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          {/* <!-- CUSTOMER div BOOTOM END --> */}
                          <p className="info" style={{ textAlign: "left" }}>
                            {" "}
                            ১) বিদ্যুৎ সাশ্রয়ের মাধ্যমে বিদ্যুৎ বিল কমান | ২)
                            বাতি /ফ্যান ব্যাবহারে সচেতন হোন |<br />
                            ৩) বিদ্যুৎ সাশ্রয়ী বাতি(CFL/T5 টিউব লাইট) ব্যবহার
                            করুন | <br />
                            [Note: visit https://cmlbd.xyz]
                          </p>
                        </div>
                        {/* <!-- CUSTOMER SECTON END -->
  
      <!--  OFFICE COPY div START --> */}
                        <div id="office-copy-section">
                          <br />
                          <hr
                            style={{
                              borderStyle: "dotted",
                              backgroundColor: "#000",
                              height: "3px",
                            }}
                          />
                          <br />
                          <table
                            style={{ width: "100%" }}
                            className="office-copy-table"
                          >
                            <tbody>
                              <tr>
                                <td colSpan="6" style={{ textAlign: "center" }}>
                                  <h2 style={{ textAlign: "center" }}>
                                    অফিস কপি
                                  </h2>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2">
                                  ক্রমিক নং :{" "}
                                  <strong>
                                    {DigitConvert(item.invoice_no)}
                                  </strong>
                                </td>
                                <td colSpan="4">
                                  মিটার নং :{" "}
                                  <strong style={{ paddingRight: "20px" }}>
                                    {DigitConvert(item.meter_no)}
                                  </strong>
                                  {/* <br /> */}হিসাব নং/গ্রাহক নং :{" "}
                                  <strong>
                                    {DigitConvert(item.customer_no)}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="2">
                                  গ্রাহকের নাম :{" "}
                                  <strong>{item.customer_name}</strong>
                                  <br />
                                  পিতা /স্বামীর নাম :{" "}
                                  <strong>{item.guardian_name}</strong>
                                  <br />
                                  ঠিকানা : <strong>{item.address}</strong>
                                </td>
                                <td colSpan="2">
                                  বিলের মাস :{" "}
                                  <strong>{CustomYearMonth(item.month)}</strong>
                                  <br />
                                  শাখা : <strong>{item.branch}</strong>
                                  <br />
                                  <br />
                                </td>
                                <td colSpan="2" style={{ fontSize: "14px" }}>
                                  মোট বিল :{" "}
                                  <strong>
                                    {" "}
                                    ৳ {DigitConvert(item.net_bill)}
                                  </strong>
                                  <br />
                                  <br />
                                  {/* <br /> */}
                                </td>
                              </tr>

                              {/* <tr> */}
                              {/* <td colSpan="6"> */}
                              {/* <br />
                                  <br />
                                  <br /> */}
                              {/* আদায়কারির স্বাক্ষর ও তারিখ */}
                              {/* </td> */}

                              {/* <!-- <td colSpan="3"></td> -->
                      <!-- <td colSpan="2" rowSpan="2"></td> --> */}
                              {/* </tr> */}
                            </tbody>
                          </table>
                          <br />
                          আদায়কারির স্বাক্ষর ও তারিখ
                        </div>

                        {/* <!-- OFFICE COPY div END --> */}
                      </div>
                      <footer className="print-footer">
                        <p style={{ fontSize: "9px" }}>Powered By DotxIT</p>
                      </footer>
                      <br className="brack_space" />

                      <style>{`@media print{.brack_space{display:none}}`}</style>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="print_option">
            <div
              className="fixed_button"
              style={{ width: "15%", right: "2rem" }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <button onClick={() => handlePrint()}>
                  <PrintIcon
                    style={{
                      // fill: "#2596be",
                      fill: "#fff",
                      color: "#fff",
                      height: "50px",
                      width: "50px",
                      cursor: "pointer",
                      // marginRight: "3rem",
                    }}
                  />
                </button>
                <button onClick={() => navigate("/admin/bill-register")}>
                  <ReplyAllIcon
                    style={{
                      fill: "#fff",
                      color: "#fff",
                      height: "50px",
                      width: "50px",
                      cursor: "pointer",
                      // marginRight: "3rem",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrintSingleInvoice;
