import React, { useEffect } from "react";
import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import Spiner from "../UI/Spiner";
import { fetchItem } from "../../store/allSlice";
import { CustomDayMonthYear, DigitConvert } from "../../store/customDate";

const CashReportTable = ({ axiosRoute }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.area);

  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
  }, [dispatch]);
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">ক্রমিক নং</TableCell>
                <TableCell className="tableCell">তারিখ</TableCell>
                <TableCell className="tableCell">বিবরন</TableCell>
                <TableCell className="tableCell">খরচের পরিমান</TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {data?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="tableCell">
                      {DigitConvert(index + 1)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {CustomDayMonthYear(item.created_date)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.description}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.income_amount
                        ? DigitConvert(item.income_amount)
                        : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default CashReportTable;
