import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { BrowserRouter } from "react-router-dom";
import store from "./store/Store";
import "./main.css";
import AppLogout from "./components/login/Logout";

import { Toaster } from "react-hot-toast";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <Provider store={store}>
        <BrowserRouter>
          <AppLogout>
            <App />
            <Toaster />
          </AppLogout>
        </BrowserRouter>
      </Provider>
    </DarkModeContextProvider>
  </React.StrictMode>
);
