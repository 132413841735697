import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import Table from "@mui/material/Table";
import PrintIcon from "@mui/icons-material/Print";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Navbar from "../navbar/Navbar";
import BillSidebar from "../billsidebar/BillSidebar";
import { DigitConvert } from "../../store/customDate";

const UnitInvoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "unit Report",
    content: () => componentRef.current,
  });
  let totalUnit = 0;
  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="print_grid" style={{ width: "92%" }}>
          <div className="center_invoice-submeter">
            <div className="body" ref={componentRef}>
              <div
                id="main-section-submeter"
                // media="print"
              >
                <TableContainer component={Paper} className="table">
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={8}>
                          <div
                            id="top-section1"
                            style={{ textAlign: "center" }}
                          >
                            <p>বিসমিল্লাহির রাহমানির রাহিম</p>
                            <p>
                              "বিদ্যুৎ জাতীয় সম্পদ অপচয় রোধে এগিয়ে আসুন,অবৈধ
                              সংযোগ থেকে বিরত থাকুন"
                            </p>
                            <h4>
                              চট্টগ্রাম মহানগর ছিন্নমুল বস্তিবাসি সমন্বয় সংগ্রাম
                              পরিষদ
                            </h4>
                            <p>
                              জঙ্গল ছলিমপুর ছিন্নমুল পূনর্বাসন প্রকল্প, ডাকঘরঃ
                              জাফরাবাদ,থানাঃসিতাকুন্ড,চট্টগ্রাম |
                            </p>
                            <p>বিদ্যুৎ বিতরন বিভাগ</p>
                            <h2 style={{ padding: "0" }}>ইউনিট রিপোর্ট</h2>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="tableCell">ক্রমিক নং</TableCell>
                        <TableCell className="tableCell">
                          বিল তৈরির তারিখ
                        </TableCell>
                        <TableCell className="tableCell">গ্রাহক নং</TableCell>
                        <TableCell className="tableCell">
                          গ্রাহকের নাম
                        </TableCell>
                        <TableCell className="tableCell">বিলের মাস</TableCell>
                        <TableCell className="tableCell">মিটার নং </TableCell>

                        <TableCell className="tableCell">ইউনিট</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      {state?.map((item, index) => {
                        let TotalUnit = parseInt(item.uses_unit);
                        if ("uses_unit" in item) {
                          totalUnit += TotalUnit;
                        }
                        return (
                          <TableRow key={index}>
                            <TableCell className="tableCell">
                              {DigitConvert(index + 1)}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.created_date_bn}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.customer_no_bn}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.customer_name}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.month_bn}
                            </TableCell>

                            <TableCell className="tableCell">
                              {item.customer_meter_no_bn}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.uses_unit_bn}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>মোট</TableCell>
                        <TableCell>{DigitConvert(totalUnit)}</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                {/* <!-- BOTTOM div END -->
    <!-- CUSTOMER SECTON START --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="print_option_submeter">
          <div
            className="fixed_button_submeter"
            style={{ width: "15%", right: "2rem" }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <button
                onClick={() => {
                  handlePrint();
                }}
              >
                <PrintIcon
                  style={{
                    fill: "#fff",
                    color: "#fff",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                />
              </button>
              <button onClick={() => navigate("/admin/report/unit-report")}>
                <ReplyAllIcon
                  style={{
                    fill: "#fff",
                    color: "#fff",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UnitInvoice;
