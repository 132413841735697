import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import Spiner from "../UI/Spiner";
import { fetchItem } from "../../store/allSlice";

const SubMeterReportTable = ({ axiosRoute }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.area);

  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
  }, [dispatch]);
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">গ্রাহক নং </TableCell>
                <TableCell className="tableCell">গ্রাহকের নাম</TableCell>
                <TableCell className="tableCell">পিতা/স্বামী নাম</TableCell>
                <TableCell className="tableCell">গ্রাহক লিঙ্গ</TableCell>
                <TableCell className="tableCell">ঠিকানা</TableCell>
                <TableCell className="tableCell">মোবাইল নং</TableCell>
                <TableCell className="tableCell">সংযোগের ধরন</TableCell>
                <TableCell className="tableCell">মিটার নং</TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {data?.map((item) => {
                return (
                  <TableRow key={item.meter_no}>
                    <TableCell className="tableCell">
                      {item.customer_no}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.customer_name} {item.guardian_name}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.guardian_name}
                    </TableCell>
                    <TableCell className="tableCell">{item.gender}</TableCell>
                    <TableCell className="tableCell">{item.address}</TableCell>
                    <TableCell className="tableCell">
                      {item.mobile_no}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.connection}
                    </TableCell>
                    <TableCell className="tableCell">{item.meter_no}</TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default SubMeterReportTable;
