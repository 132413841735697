import { createSlice } from "@reduxjs/toolkit";
import Axios from "../Axios";
import toast from "react-hot-toast";

export const CustomerSearchSlice = createSlice({
  name: "customerSearch",
  initialState: {
    searchData: [],
  },
  reducers: {
    setFetchItem: (state, action) => {
      state.searchData = action.payload;
    },
  },
});

export const { setFetchItem } = CustomerSearchSlice.actions;
export default CustomerSearchSlice.reducer;
export const fetchItemData = (url) => {
  return async (dispatch, getState) => {
    try {
      const response = await Axios.get(`/${url}/`);

      dispatch(setFetchItem(response.data));
    } catch (error) {
      toast(error.response);
    }
  };
};
