import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchItem,
  dataEdit,
  setShowButton,
  deleteItem,
} from "../../store/allSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Alert from "../UI/Alert";
import { CustomDayMonthYear, CustomYearMonth } from "../../store/customDate";
import Axios from "../../Axios";
import Spiner from "../UI/Spiner";

const BillCollectionTable = ({
  EditHandler,
  axiosRoute,
  currentPosts,
  setSelectValue,
  setCount,
  setEditValue,
  indexOfFirstPost,
  indexOfLastPost,
}) => {
  let { data, search, isLoading } = useSelector((state) => state.billregister);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [mount, setMount] = useState(true);

  const [indexId, setIndexId] = useState(null);
  const [title, setTitle] = useState(null);

  let keys = ["customer_meter_no", "customer_name", "customer_no"];
  let loansData = data?.payment;
  useEffect(() => {
    if (mount) {
      dispatch(fetchItem(axiosRoute));
    }
    return () => setMount(false);
  }, [dispatch]);

  const deleteHandler = (id) => {
    dispatch(deleteItem(id, axiosRoute));
    setOpen(false);
  };
  const model = () => {
    setOpen((prevState) => !prevState);
  };
  const editHandler = (id) => {
    Axios.get(`payment-collection/${id}`, (res, req) => {
      res.json();
    }).then((data) => {
      let paymentData = data.data.payment[0];
      // paymentData.due_deposit = paymentData.bill_due;
      console.log(paymentData)
      setEditValue(paymentData);
      setSelectValue(data.data.search_customer);
    });
    EditHandler(id);
    dispatch(dataEdit());
    dispatch(setShowButton());
  };
  function renderItemFunction() {
    let searchItem;
    function dataSearch() {
      let searchArea = loansData.filter((row) =>
        keys.some((key) => {
          if (row[key] === null || row[key] === undefined) {
            searchItem = "";
          } else {
            searchItem = row[key]
              .toLowerCase()
              .includes(search.trim().toLowerCase());
          }
          return searchItem;
        })
      );
      let searchAreaData = searchArea.slice(indexOfFirstPost, indexOfLastPost);

      setCount(searchArea.length);
      return searchAreaData;
    }

    return (search.trim() === "" ? currentPosts : dataSearch()).map((row) => {
      return (
        <TableRow key={row.id}>
          <TableCell className="tableCell">
            {CustomDayMonthYear(row.created_date)}
          </TableCell>
          <TableCell className="tableCell">{row.customer_no}</TableCell>
          <TableCell className="tableCell">{row.customer_name}</TableCell>

          <TableCell className="tableCell">{row.customer_meter_no}</TableCell>

          <TableCell className="tableCell">
            {CustomYearMonth(row.bill_month)}
          </TableCell>
          <TableCell className="tableCell">{row.bill_amount}</TableCell>
          <TableCell className="tableCell">{row.amount}</TableCell>
          <TableCell className="tableCell">{row.bill_due}</TableCell>
          <TableCell className="tableCell">{row.due_deposit}</TableCell>
          <TableCell className="tableCell">
            <div className="group_button">
              <button
                onClick={() => editHandler(row.id)}
                className="button_table"
                aria-label="delete"
              >
                <ModeEditIcon className="edit_icon" />
              </button>
              <button
                onClick={() => {
                  model();
                  setIndexId(row.id);
                  setTitle(`${row.customer_name}`);
                }}
                className="button_table"
                aria-label="delete"
              >
                <DeleteOutlineIcon className="delete_icon" />
              </button>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">বিল তৈরির তারিখ </TableCell>
                <TableCell className="tableCell">গ্রাহক নং </TableCell>

                <TableCell className="tableCell">গ্রাহকের নাম </TableCell>
                <TableCell className="tableCell">মিটার নং</TableCell>

                <TableCell className="tableCell">বিলের মাস</TableCell>
                <TableCell className="tableCell_actions"> মোট বিল</TableCell>
                <TableCell className="tableCell_actions">
                  {" "}
                  পরিশোধিত টাকা{" "}
                </TableCell>
                <TableCell className="tableCell_actions"> ছাড় </TableCell>
                <TableCell className="tableCell">বকেয়া বিল</TableCell>
                <TableCell className="tableCell_actions">কর্ম </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderItemFunction()}</TableBody>
          </Table>
          {open && (
            <Alert
              areaDeleteHandler={deleteHandler}
              open={open}
              model={model}
              title={title}
              id={indexId}
            />
          )}
        </TableContainer>
      )}
    </>
  );
};

export default BillCollectionTable;
