import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Table from "@mui/material/Table";
import PrintIcon from "@mui/icons-material/Print";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Navbar from "../navbar/Navbar";
import BillSidebar from "../billsidebar/BillSidebar";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { CustomDayMonthYear, DigitConvert } from "../../store/customDate";

const ExpanceReportInvoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "Expance Report",
    content: () => componentRef.current,
  });
  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="print_grid" style={{ width: "92%" }}>
          <div className="center_invoice-submeter">
            <div className="body" ref={componentRef}>
              <div>
                <div id="main-section-submeter">
                  <TableContainer
                    component={Paper}
                    className="table"
                    classes="invoiceTable"
                    style={{ padding: "0" }}
                  >
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={8} className="top-section1">
                            <div className="top-section1">
                              <p>বিসমিল্লাহির রাহমানির রাহিম</p>
                              <p>
                                "বিদ্যুৎ জাতীয় সম্পদ অপচয় রোধে এগিয়ে আসুন,অবৈধ
                                সংযোগ থেকে বিরত থাকুন"
                              </p>
                              <h4>
                                চট্টগ্রাম মহানগর ছিন্নমুল বস্তিবাসি সমন্বয়
                                সংগ্রাম পরিষদ
                              </h4>
                              <p>
                                জঙ্গল ছলিমপুর ছিন্নমুল পূনর্বাসন প্রকল্প, ডাকঘরঃ
                                জাফরাবাদ,থানাঃসিতাকুন্ড,চট্টগ্রাম |
                              </p>
                              <p>বিদ্যুৎ বিতরন বিভাগ</p>
                              <h2>খরচের রিপোর্ট </h2>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableCell">ক্রমিক নং</TableCell>
                          <TableCell className="tableCell">তারিখ</TableCell>
                          <TableCell className="tableCell">বিবরন</TableCell>
                          <TableCell className="tableCell">
                            খরচের পরিমান
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        {state?.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className="tableCell">
                                {DigitConvert(index + 1)}
                              </TableCell>
                              <TableCell className="tableCell">
                                {CustomDayMonthYear(item.date)}
                              </TableCell>
                              <TableCell className="tableCell">
                                {item.description}
                              </TableCell>
                              <TableCell className="tableCell">
                                {DigitConvert(item.amount)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableHead>
                    </Table>
                  </TableContainer>
                  {/* <!-- BOTTOM div END -->
    <!-- CUSTOMER SECTON START --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="print_option_submeter">
          <div
            className="fixed_button_submeter"
            style={{ width: "15%", right: "2rem" }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <button
                onClick={() => {
                  handlePrint();
                }}
              >
                <PrintIcon
                  style={{
                    fill: "#fff",
                    color: "#fff",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                />
              </button>
              <button onClick={() => navigate("/admin/report/expansereport")}>
                <ReplyAllIcon
                  style={{
                    fill: "#fff",
                    color: "#fff",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpanceReportInvoice;
