// import "./newform.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { fetchItem } from "../../store/allSlice";
import { BillReportForm } from "../../billFormData";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import BillSidebar from "../billsidebar/BillSidebar";
import Navbar from "../navbar/Navbar";
import BillReportTable from "../billTable/BillReportTable";
import PrintIcon from "@mui/icons-material/Print";
import { fetchItemData } from "../../store/customerSearchSlice";
import {
  currentDateYearMonth,
  previousDateYearMonth,
} from "../../store/customDate";

const BillReport = ({ axiosRoute }) => {
  let { data } = useSelector((state) => state.allinvoice);
  let { searchData } = useSelector((state) => state.searchCustomer);
  document.title = "Bill Report";
  let filterData = data?.results;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allData, setAllData] = useState({});
  const [selectValue, setSelectValue] = useState({});

  const onChangeHandler = (event) => {
    setAllData({
      ...allData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (Object.keys(searchData).length === 0) {
      dispatch(fetchItemData("search-customer"));
    }
  }, [dispatch]);
  const onSearch = () => {
    let filter = "";

    let form_date = allData.from ? allData.from : previousDateYearMonth;
    let to_date = allData.to ? allData.to : currentDateYearMonth;

    if (selectValue.value === 0 || selectValue.value === undefined) {
      filter = `bill-report/?month=${form_date}&to_date=${to_date}`;
    } else {
      filter = `bill-report/?month=${form_date}&to_date=${to_date}&customer_id=${selectValue.value}`;
    }

    dispatch(fetchItem(filter));
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();

    navigate("/admin/report/billreportinvoice", { state: filterData });
  };

  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="bottom">
          <div className="right">
            <span style={{ color: "red" }}></span>
            <form onSubmit={onSubmitHandler}>
              <div className="form_input_invoice">
                {BillReportForm.map((input, index) => {
                  return (
                    <div className="formInput" key={index}>
                      <label>{input.label}</label>
                      {input.type === "select" ? (
                        <Select
                          className="search_dropdown"
                          name={input.db_name}
                          value={
                            Object.keys(selectValue).length === 0
                              ? searchData[0]
                              : selectValue
                          }
                          defaultValue={selectValue}
                          onChange={(e) => setSelectValue(e)}
                          options={searchData}
                        />
                      ) : (
                        <input
                          autoComplete="off"
                          value={allData[input.db_name]}
                          onChange={onChangeHandler}
                          name={input.db_name}
                          type={input.type}
                          placeholder={input.placeholder}
                          required={input.required}
                          readOnly={input.readOnly}
                          hidden={input.hidden}
                        />
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="form_button centerButton reportButton">
                <button
                  className="button"
                  type="button"
                  onClick={() => onSearch()}
                >
                  <SendIcon className="icon" />
                  Search
                </button>
                <button className="button" type="submit">
                  <PrintIcon className="icon" />
                  Print
                </button>
                <button
                  className="button"
                  type="button"
                  onClick={() => navigate("/admin/report")}
                >
                  <ArrowBackIosIcon className="icon" />
                  Go Back
                </button>
              </div>
            </form>
            <h2>বিল রিপোর্ট</h2>
          </div>
        </div>
        <div className="Table">
          <BillReportTable axiosRoute={axiosRoute} />
        </div>
      </div>
    </div>
  );
};

export default BillReport;
