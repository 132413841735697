// import "./newform.scss";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CustomerTable from "../billTable/CustomerTable";
// import Spiner from "../UI/Spiner";
import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
} from "../../store/allSlice";
import { Customer } from "../../billFormData";
import PaginationButton from "../UI/PaginationButton";
import Billsidebar from "../billsidebar/BillSidebar";
import Navbar from "../navbar/Navbar";

const CustomerForm = ({ Title, editTitle, axiosRoute, field_error }) => {
  let { data, showButton, Edit, search, count } = useSelector(
    (state) => state.customer
  );
  document.title = "Customer";

  let { searchData } = useSelector((state) => state.searchCustomer);
  const dispatch = useDispatch();

  const [editId, setEditId] = useState();
  const [allData, setAllData] = useState({});
  const [editValue, setEditValue] = useState({});
  const [selectValue, setSelectValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  // const [count, setCount] = useState(0);

  const [genderSelect, setGenderSelect] = useState({});
  const [bloodGroupSelect, setBloodGroupSelect] = useState({});
  const [connectionSelect, setConnectionSelect] = useState({});
  const [areaSelect, setAreaSelect] = useState({});
  const [meterSelect, setMeterSelect] = useState({});
  const [branchSelect, setBranchSelect] = useState({});

  const [searchMeterData, setSearchMeterData] = useState();

  let customerData = data?.customer;
  let searchGender = data?.gender;
  let searchBloodGroup = data?.blood_group;
  // let searchMeter = customer?.search_meter;
  let searchConnectionType = data?.connection_type;
  let searchArea = data?.area;
  let searchResults = data?.result;
  let searchBranch = data?.department;
  let customerAutoNo = data?.customer_no;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = _.slice(
    customerData ?? data,
    indexOfFirstPost,
    indexOfLastPost
  );

  let key = null;
  let dropdownKey = {
    gender: searchGender,
    blood_group: searchBloodGroup,
    parent_id: searchData,
    connection_type_id: searchConnectionType,
    area_id: searchArea,
    branch_id: searchBranch,
  };

  const onChangeHandler = (event) => {
    // let { name, value, checked } = event.target;

    if (Edit) {
      let connection_charge,
        connection_payment,
        connection_due = 0;

      if (
        event.target.name === "connection_payment" ||
        event.target.name === "connection_charge"
      ) {
        connection_charge =
          event.target.name === "connection_charge"
            ? parseInt(event.target.value)
            : parseInt(0 || editValue.connection_charge);
        connection_payment =
          event.target.name === "connection_payment"
            ? parseInt(event.target.value)
            : 0 || parseInt(editValue.connection_payment);
        connection_due = connection_charge - connection_payment;
        setEditValue({
          ...editValue,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.targetchecked
              : event.targetvalue,
          [key]: selectValue.value,
          blood_group: editValue.blood_group,
          connection_charge: connection_charge,
          connection_payment: connection_payment,
          connection_due: connection_due,
        });
      } else {
        setEditValue({
          ...editValue,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
          [key]: selectValue.value,
          branch_id: editValue.branch_id,
          gender: editValue.gender,
          blood_group: editValue.blood_group,
          area_id: editValue.area_id,
          connection_type_id: editValue.connection_type_id,
          connection_due: editValue.connection_due,
        });
      }
    } else {
      let connection_charge,
        connection_payment,
        connection_due = 0;
      if (
        event.target.name === "connection_payment" ||
        event.target.name === "connection_charge"
      ) {
        connection_charge =
          event.target.name === "connection_charge"
            ? parseInt(event.target.value)
            : parseInt(allData.connection_charge);
        if (isNaN(connection_charge)) {
          connection_charge = 0;
        }
        connection_payment =
          event.target.name === "connection_payment"
            ? parseInt(event.target.value)
            : 0 || parseInt(allData.connection_payment);
        connection_due = connection_charge - connection_payment;
        setAllData({
          ...allData,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
          [key]: selectValue.value,
          connection_charge: connection_charge,
          connection_payment: connection_payment,
          connection_due: connection_due,
        });
      } else {
        setAllData({
          ...allData,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
          [key]: selectValue.value,
          branch_id: branchSelect.value,
          gender: genderSelect.value,
          blood_group: bloodGroupSelect.value,
          area_id: areaSelect.value,
          connection_type_id: connectionSelect.value,
          connection_due: allData.connection_due,
        });
      }
    }
  };

  const onChangeSelectedValue = (e, k) => {
    if (Edit) {
      setEditValue({
        ...editValue,
        [k]: e.value,
      });
    } else {
      setAllData({
        ...allData,
        [k]: e.value,
      });
    }
    if (k === "gender") {
      return setGenderSelect(e);
    } else if (k === "blood_group") {
      return setBloodGroupSelect(e);
    } else if (k === "connection_type_id") {
      return setConnectionSelect(e);
    } else if (k === "parent_id") {
      let tempData = Edit ? { ...editValue } : allData;
      if ("customer_no" in tempData || "meter_no" in tempData) {
        delete tempData.customer_no;
        delete tempData.meter_no;
        Edit ? setEditValue(tempData) : setAllData(tempData);
      }
      let searchMeterResult = searchResults.find((item) => item.id === e.value);
      if (typeof searchMeterResult === "undefined") {
        searchMeterResult = { id: 0, customer_no: "", meter_no: "" };
      }

      setSearchMeterData(searchMeterResult);

      return setMeterSelect(e);
    } else if (k === "branch_id") {
      return setBranchSelect(e);
    } else if (k === "area_id") {
      return setAreaSelect(e);
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (Edit) {
      try {
        if (!("customer_no" in editValue)) {
          Object.assign(...editValue, {
            customer_no: searchMeterData.customer_no,
          });
        }
        if (!("meter_no" in editValue)) {
          Object.assign(...editValue, { meter_no: searchMeterData.meter_no });
        }
        dispatch(editItem(editId, editValue, axiosRoute));
      } catch (error) {}
    } else {
      if (!("customer_no" in allData)) {
        Object.assign(allData, {
          customer_no: searchMeterData.customer_no || customerAutoNo,
        });
      }
      if (!("meter_no" in allData)) {
        Object.assign(allData, { meter_no: searchMeterData.meter_no });
      }

      dispatch(postItem(allData, axiosRoute));
      setTimeout(() => {
        if (!field_error) {
          document.getElementById("clear").click();
        }
      }, 300);
    }
  };

  const goBack = () => {
    // dispatch(fetchItem(axiosRoute));
    dispatch(setShowButtonHide());
    dispatch(dataAdd());
    handleReset();
    dispatch(searchBarHandlerHide(true));
  };

  const EditHandler = (id) => {
    setEditId(id);
    const billRegisterEditObject = customerData.find((item) => item.id === id);

    setEditValue(billRegisterEditObject);
  };
  const handleReset = () => {
    setSelectValue({});
    setAllData({});
    setEditValue({});
    setGenderSelect({});
    setBloodGroupSelect({});
    setSearchMeterData({});
    setMeterSelect({});
    setConnectionSelect({});
    setBranchSelect({});
    setAreaSelect({});
  };
  const AddHandler = () => {
    dispatch(searchBarHandlerHide(false));
    dispatch(dataAdd());
    dispatch(setShowButton());
    handleReset();
  };
  return (
    <div className="newform">
      <Navbar />
      <Billsidebar />

      <div className="newContainer">
        <div className="top">
          <h1>{Edit ? editTitle : Title}</h1>

          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        {showButton ? (
          <span></span>
        ) : (
          <div className="showEntites">
            <span>show </span>
            <select onChange={(event) => setPostPerPage(event.target.value)}>
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span> entites</span>
          </div>
        )}

        {showButton ? (
          <div className="bottom">
            <div className="right">
              <form id="clear_data" onSubmit={onSubmitHandler}>
                <div className="form_input">
                  {Customer.map((input, index) => {
                    let dynamic;

                    if (
                      searchMeterData === undefined ||
                      Object.keys(searchMeterData).length === 0
                    ) {
                      dynamic = data?.[input.db_name];
                    } else {
                      dynamic = searchMeterData[input.db_name];
                    }

                    if (input.type === "select") {
                      key = input.db_name;
                      // type = input.type
                      // AH Start

                      function selectionFunction(p) {
                        const lgSelectData = dropdownKey[p]?.filter(
                          (item) => item.value === editValue[input.db_name]
                        );

                        switch (p) {
                          case "parent_id":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit && Object.keys(meterSelect).length === 0
                                    ? lgSelectData
                                    : Object.keys(meterSelect).length === 0
                                    ? searchData[0]
                                    : meterSelect
                                }
                                defaultValue={searchData}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchData}
                              />
                            );
                          case "gender":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit && Object.keys(genderSelect).length === 0
                                    ? lgSelectData
                                    : Object.keys(genderSelect).length === 0
                                    ? searchGender[0]
                                    : genderSelect
                                }
                                defaultValue={genderSelect}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchGender}
                              />
                            );
                          case "blood_group":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit &&
                                  (bloodGroupSelect === undefined ||
                                    Object.keys(bloodGroupSelect).length === 0)
                                    ? typeof lgSelectData === "undefined"
                                      ? searchBloodGroup
                                      : lgSelectData
                                    : Object.keys(bloodGroupSelect).length === 0
                                    ? searchBloodGroup
                                    : bloodGroupSelect
                                }
                                defaultValue={bloodGroupSelect}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchBloodGroup}
                              />
                            );
                          case "area_id":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit && Object.keys(areaSelect).length === 0
                                    ? lgSelectData
                                    : Object.keys(areaSelect).length === 0
                                    ? searchArea[0]
                                    : areaSelect
                                }
                                defaultValue={areaSelect}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchArea}
                              />
                            );
                          case "branch_id":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit &&
                                  (Object.keys(branchSelect).length === 0 ||
                                    branchSelect === undefined)
                                    ? lgSelectData
                                    : Object.keys(branchSelect).length === 0
                                    ? searchBranch[0]
                                    : branchSelect
                                }
                                defaultValue={branchSelect}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchBranch}
                              />
                            );
                          case "connection_type_id":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit &&
                                  Object.keys(connectionSelect).length === 0
                                    ? lgSelectData
                                    : Object.keys(connectionSelect).length === 0
                                    ? searchConnectionType[0]
                                    : connectionSelect
                                }
                                defaultValue={connectionSelect}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchConnectionType}
                              />
                            );
                          default:
                            return;
                        }
                      }
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          {selectionFunction(input.db_name)}
                        </div>
                      );
                    } else {
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <input
                            className={
                              input.readOnly ? "input_readOnly" : "input"
                            }
                            style={{
                              border:
                                input.db_name === field_error
                                  ? "1px solid red"
                                  : null,
                            }}
                            autoComplete="off"
                            value={
                              (Edit && input.type === "date"
                                ? editValue[input.db_name]
                                : allData[input.db_name]) ??
                              (Edit
                                ? editValue[input.db_name] ?? dynamic
                                : allData[input.db_name] ?? dynamic)
                            }
                            onChange={onChangeHandler}
                            name={input.db_name}
                            checked={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                            }
                            type={input.type}
                            placeholder={input.placeholder}
                            required={input.required}
                            readOnly={input.readOnly}
                            hidden={input.hidden}
                            maxLength={input.maxLength}
                            // max={input.maxLength}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="form_button">
                  <button className="button" type="submit">
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => goBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            <CustomerTable
              EditHandler={EditHandler}
              axiosRoute={axiosRoute}
              currentPosts={currentPosts}
              setPostPerPage={setPostPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
            />

            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={search.trim() === "" ? customerData?.length : count}
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerForm;
