import React, { useState } from "react";
// import './billsidebar.scss'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetData,
  setShowButtonHide,
  setSearchTermRemove,
} from "../../store/allSlice";

function Sidebaritem({ item }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  if (item.childrens) {
    return (
      <div className={open ? "sidebar-item open" : "sidebar-item"}>
        <div className="sidebar-title" onClick={() => setOpen(!open)}>
          <span>
            {item.icon && <i className={item.icon}></i>}
            {item.title}
          </span>
          <i className="bi-chevron-down toggle-btn"></i>
        </div>
        <div className="sidebar-content">
          {item.childrens.map((child, i) =>
            // <Sidebaritem key={i} item={child} />
            child.is_active === true ? <Sidebaritem key={i} item={child} /> : ""
          )}
        </div>
      </div>
    );
  } else {
    return (
      <Link
        onClick={() => {
          if (item.path === window.location.pathname) {
            dispatch(setShowButtonHide());
          } else {
            dispatch(setSearchTermRemove());
            dispatch(resetData());
            dispatch(setShowButtonHide());
          }
        }}
        to={item.path || "#"}
        className="sidebar-item plain"
      >
        {item.icon && <i className={item.icon}></i>} {item.title}
      </Link>
    );
  }
}

export default Sidebaritem;
