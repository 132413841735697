import React from "react";
import Pagination from "@mui/material/Pagination";

const PaginationButton = ({
  postPerPage,
  totalPosts,
  setCurrentPage,
  currentPage,
}) => {
  const handleChange = (event, value) => {
    setCurrentPage(parseInt(value));
  };
  const pageNumber = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
    pageNumber.push(i);
  }

  return (
    <Pagination
      page={currentPage}
      count={pageNumber.length}
      onChange={handleChange}
      color="primary"
    />
  );
};
export default PaginationButton;
