const digit_month_dict = {
  "01": "জানুয়ারী",
  "02": "ফ্রেবুয়ারি",
  "03": "মার্চ",
  "04": "এপ্রিল",
  "05": "মে",
  "06": "জুন",
  "07": "জুলাই",
  "08": "অগাস্ট",
  "09": "সেপ্টম্বর",
  10: "অক্টোবর",
  11: "নভেম্বর",
  12: "ডিসেম্বর",
};

const CustomDate = (date) => {
  // const date = new Date(x)
  // const formattedDate = date.toLocaleDateString("en-US", {
  //   day: "numeric",
  //   month: "numeric",
  //   year: "numeric"
  // })
  const formattedDate = new Date(date).toISOString().slice(0, 10);
  return formattedDate;
};
const CustomYearMonth = (date) => {
  let dateSplit = date.split("-");

  // const monthYear = new Date(date);
  // const formattedDate = monthYear.toLocaleDateString("en-US", {
  //   //   day: "numeric",
  //   month: "short",
  //   year: "numeric",
  // });
  const formattedDate =
    digit_month_dict[dateSplit[1]] + " - " + DigitConvert(dateSplit[0]);
  // console.log(digit_month_dict[dateSplit[1]])
  // console.log(DigitConvert(dateSplit[0])-digit_month_dict[dateSplit[1]])
  // const formattedDate = new Date(date).toISOString().slice(0,10)
  return formattedDate;
};
const CustomDayMonthYear = (date) => {
  let dateSplit = date.split("-");
  const formattedDate =
    DigitConvert(dateSplit[2]) +
    "-" +
    digit_month_dict[dateSplit[1]] +
    "-" +
    DigitConvert(dateSplit[0]);
  // const monthYear = new Date(date);
  // const formattedDate = monthYear.toLocaleDateString("en-US", {
  //   day: "numeric",
  //   month: "long",
  //   year: "numeric",
  // });

  return formattedDate;
};
let emptyString = "";

const digits_dict = {
  0: "০",
  1: "১",
  2: "২",
  3: "৩",
  4: "৪",
  5: "৫",
  6: "৬",
  7: "৭",
  8: "৮",
  9: "৯",
};
const DigitConvert = (d) => {
  let dgt = d.toString();

  let digit_convert = "";
  for (let i of dgt) {
    if (i in digits_dict) {
      digit_convert += digits_dict[i];
    } else {
      digit_convert += i;
    }
  }
  return digit_convert;
};

// Get the current date
const currentDate = new Date();

// Get the current year, month, and date
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth(); // 0-11, where 0 = January, 11 = December
const currentDay = currentDate.getDate();

// Calculate the previous month (handle January to December rollover)
const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

// Create a date for the last day of the previous month
const previousMonthDate = new Date(previousMonthYear, previousMonth + 1, 0); // Last day of the previous month
const lastDayOfPreviousMonth = previousMonthDate.getDate();

// Ensure the day doesn't exceed the last day of the previous month
const previousDay =
  currentDay > lastDayOfPreviousMonth ? lastDayOfPreviousMonth : currentDay;

const currentDateYearMonth = `${previousMonthYear}-${String(
  currentMonth + 1
).padStart(2, "0")}-${String(previousDay).padStart(2, "0")}`;
// Format the previous month and date to YYYY-MM-DD format
const previousDateYearMonth = `${previousMonthYear}-${String(
  previousMonth + 1
).padStart(2, "0")}-${String(previousDay).padStart(2, "0")}`;
// Format the month and date to YYYY-MM format
const yearMonth = `${previousMonthYear}-${String(currentMonth + 1).padStart(
  2,
  "0"
)}`;

export {
  CustomDate,
  CustomYearMonth,
  CustomDayMonthYear,
  emptyString,
  DigitConvert,
  currentDateYearMonth,
  previousDateYearMonth,
  yearMonth,
};
