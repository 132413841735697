import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchItem,
  dataEdit,
  setShowButton,
  deleteItem,
} from "../../store/allSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PrintIcon from "@mui/icons-material/Print";

import Alert from "../UI/Alert";
import { CustomDayMonthYear, CustomYearMonth } from "../../store/customDate";
import { useNavigate } from "react-router-dom";
import Spiner from "../UI/Spiner";
import Axios from "../../Axios";

const BillRegisterTable = ({
  EditHandler,
  setEditValue,
  axiosRoute,
  currentPosts,
  setCount,
  indexOfFirstPost,
  indexOfLastPost,
  setSelectValue,
}) => {
  let { data, search, isLoading } = useSelector((state) => state.billregister);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [indexId, setIndexId] = useState(null);
  const [title, setTitle] = useState(null);

  let keys = [
    "invoice_no",
    "customer_meter_no",
    "customer_name",
    "customer_no",
  ];
  let loansData = data?.bill_register;

  const onSubmitHandler = (row) => {
    Axios.get(
      `print-invoice/?invoice_form=${row.invoice_no}&invoice_to=${row.invoice_no}&bill_month=${row.month}`,
      (res, req) => {
        res.json();
      }
    ).then((data) => {
      navigate("/admin/report/singleinvoice", { state: data.data });
    });
  };
  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
  }, [dispatch]);

  const deleteHandler = (id) => {
    dispatch(deleteItem(id, axiosRoute));

    setOpen(false);
  };
  const model = () => {
    setOpen((prevState) => !prevState);
  };
  const editHandler = (id) => {
    Axios.get(`bill-register/${id}`, (res, req) => {
      res.json();
    }).then((data) => {
      let dataCopy = data.data.bill_register[0];
      let customerCopy = data.data.search_customer_list[0];

      dataCopy.total_bill = dataCopy?.net_bill;

      dataCopy.line_charge = customerCopy?.line_charge;
      dataCopy.service_charge = customerCopy?.service_charge;
      dataCopy.demand_charge = customerCopy?.demand_charge;

      setEditValue(dataCopy);
      setSelectValue(data.data.search_customer_list);
    });
    EditHandler(id);
    dispatch(dataEdit());
    dispatch(setShowButton());
  };
  function renderItemFunction() {
    let searchItem;
    function dataSearch() {
      let searchArea = loansData.filter((row) =>
        keys.some((key) => {
          if (row[key] === null || row[key] === undefined) {
            searchItem = "";
          } else {
            searchItem = row[key]
              .toLowerCase()
              .includes(search.trim().toLowerCase());
          }
          return searchItem;
        })
      );
      let searchAreaData = searchArea.slice(indexOfFirstPost, indexOfLastPost);

      setCount(searchArea.length);
      return searchAreaData;
    }
    return (search.trim() === "" ? currentPosts : dataSearch()).map((row) => {
      return (
        <TableRow key={row.id}>
          <TableCell className="tableCell">{row.invoice_no}</TableCell>
          <TableCell className="tableCell">
            {CustomDayMonthYear(row.created_date)}
          </TableCell>
          <TableCell className="tableCell">{row.customer_meter_no}</TableCell>
          <TableCell className="tableCell">{row.customer_name}</TableCell>
          <TableCell className="tableCell">{row.customer_no}</TableCell>
          <TableCell className="tableCell">
            {CustomYearMonth(row.month)}
          </TableCell>
          <TableCell className="tableCell">{row.total_bill}</TableCell>
          <TableCell className="tableCell">
            <div className="group_button">
              <button
                onClick={() => onSubmitHandler(row)}
                className="button_table"
                aria-label="print"
              >
                <PrintIcon className="edit_icon" />
              </button>
              <button
                onClick={() => editHandler(row.id)}
                className="button_table"
                aria-label="delete"
              >
                <ModeEditIcon className="edit_icon" />
              </button>
              <button
                onClick={() => {
                  model();
                  setIndexId(row.id);
                  setTitle(`${row.customer_name}`);
                }}
                className="button_table"
                aria-label="delete"
              >
                <DeleteOutlineIcon className="delete_icon" />
              </button>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">ইনভয়েস নং </TableCell>
                <TableCell className="tableCell">বিল তৈরির তারিখ </TableCell>
                <TableCell className="tableCell">মিটার নং</TableCell>
                <TableCell className="tableCell">গ্রাহকের নাম </TableCell>
                <TableCell className="tableCell">গ্রাহক নং </TableCell>
                <TableCell className="tableCell">বিলের মাস</TableCell>
                <TableCell className="tableCell_actions"> মোট বিল</TableCell>
                <TableCell className="tableCell_actions">কর্ম </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderItemFunction()}</TableBody>
          </Table>
          {open && (
            <Alert
              areaDeleteHandler={deleteHandler}
              open={open}
              model={model}
              title={title}
              id={indexId}
            />
          )}
        </TableContainer>
      )}
    </>
  );
};

export default React.memo(BillRegisterTable);
