import React from "react";
import Sidebaritem from "./Sidebaritem";
// import items from "../../sidebar.json";
// import './billsidebar.scss'

function BillSidebar({  }) {
  let items=JSON.parse(window.localStorage.getItem('menu'))
  return (
    <div className="sidebar">
      <div className="top">
        <span className="logo">BillSystem</span>
      </div>
      <div className="center">
        {items ? items.map((item, i) => (
          
          // <Sidebaritem key={i} item={item}/>
          item.is_active===true ? <Sidebaritem key={i} item={item} /> :''
        )):""}{" "}
      </div>
    </div>
  );
}

export default BillSidebar;
