import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { DarkModeContext } from "./context/darkModeContext";
import Dashboard from "./pages/home/Dashboard";

import AreaForm from "./components/billForm/AreaForm";
import BranchForm from "./components/billForm/BranchForm";
import EmployeeForm from "./components/billForm/EmployeeForm";
import Users from "./pages/Users/Users";
import ConnectionForm from "./components/billForm/ConnectionForm";
import DesignationForm from "./components/billForm/DesignationForm";
import BankForm from "./components/billForm/BankForm";
import LoanGiverForm from "./components/billForm/LoanGiverForm";
import PrintAllInvoice from "./components/Invoice/PrintAllinvoice";
import LoanForm from "./components/billForm/LoanForm";
import LoanPaymentForm from "./components/billForm/LoanPaymentForm";
import CustomerForm from "./components/billForm/CustomerForm";
import Login from "./components/login/login";
import BillRegisterForm from "./components/billForm/BillRegisterForm";
import PrintAllForm from "./components/billForm/PrintAllForm";
import Reports from "./components/report/Reports";
import BillReport from "./components/report/BillReport";
import PaidBillReport from "./components/report/PaidBillReport";
import UnpaidBillReport from "./components/report/UnpaidBillReport";
import UnitReport from "./components/report/UnitReport";
import ExpanceReport from "./components/report/ExpanceReport";
import CashReport from "./components/report/CashReport";
import LoanReport from "./components/report/LoanReport";
import SubMeterReport from "./components/report/SubMeterReport";
import BillCollectionForm from "./components/billForm/BillCollectionForm";
import EmployeeSalaryForm from "./components/billForm/EmployeeSalaryForm";
import BankDeposit from "./components/billForm/BankDepositForm";
import PrintSingleInvoice from "./components/Invoice/PrintSingleinvoice";
import SubMeterInvoice from "./components/Invoice/SubMeterInvoice";
import PaidBillInvoice from "./components/Invoice/PaidBillInvoice";
import BillReportInvoice from "./components/Invoice/BillReportInvoice";
import UnPaidBillInvoice from "./components/Invoice/UnPaidBillInvoice";
import UnitInvoice from "./components/Invoice/UnitInvoice";
import ExpanceForm from "./components/billForm/ExpanceForm";
import BankDepositReport from "./components/report/BankDepositReport";
import BankDepositReportInvoice from "./components/Invoice/BankDepositReportInvoice";
import LoanReportInvoice from "./components/Invoice/LoanReportInvoice";
import ExpanceReportInvoice from "./components/Invoice/ExpanceReportInvoice";
import ExpanceIncomeReport from "./components/report/ExpanceIncomeReport";
import ExpanceIncomeReportInvoice from "./components/Invoice/ExpanceIncomeReportInvoice";
import ExpanceIncomeSummmeryReport from "./components/report/ExpanceIncomeSummeryReport";
import ExpanceIncomeSummeryReportInvoice from "./components/Invoice/ExpanceIncomeSummeryReportInvoice";
import AccountRoleForm from "./components/billForm/AccountRoleForm";
import Refresh from "./components/login/Refresh";
import CashReportInvoice from "./components/Invoice/CashReportInvoice";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import ChangePassword from "./components/login/ChangePassword";
import CustomerLogin from "./components/login/CustomerLogin";
import ErrorPage from "./pages/home/404";

function App() {
  const [isAuthenicated, setIsAuthenicated] = useState(false);
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    window.addEventListener("popstate", function (e) {
      if (e.state["usr"] === null) {
        e.currentTarget["location"].reload();
      }
    });
  }, []);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <Refresh setIsAuthenicated={setIsAuthenicated} />
      <Routes>
        <Route path="/user" element={<Users />} />
        <Route path="/" element={<CustomerLogin />} />

        {!isAuthenicated ? (
          <>
            <Route path="admin/" element={<Login axiosRoute="login" />} />
            <Route path="admin/login" element={<Login axiosRoute="login" />} />
          </>
        ) : (
          ""
        )}

        <Route element={<PrivateRoute />}>
          <Route path="*" element={<ErrorPage />} />

          <Route
            path="/admin/dashboard"
            element={
              <Dashboard
                setIsAuthenicated={setIsAuthenicated}
                axiosRoute="dashboard"
              />
            }
          />

          <Route
            path="/admin/add_customer"
            element={
              <CustomerForm
                axiosRoute="customer"
                // inputs={Customer}
                Title="গ্রাহক"
                editTitle="গ্রাহক পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/bill-register"
            element={
              <BillRegisterForm
                axiosRoute="bill-register"
                Title="গ্রাহক বিল রেজিস্টার"
                editTitle="গ্রাহক বিল রেজিস্টার পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/paid"
            element={
              <BillCollectionForm
                axiosRoute="payment-collection"
                Title="গ্রাহক বিল কালেকশন "
                editTitle="গ্রাহক বিল কালেকশন পরিবর্তন"
              />
            }
          />

          <Route
            path="/admin/print_all_invoice"
            element={
              <PrintAllForm
                axiosRoute="print-invoice"
                // title="ইনভয়েস"
              />
            }
          />
          <Route path="/admin/printallinvoice" element={<PrintAllInvoice />} />

          <Route
            path="/admin/add_bank"
            element={
              <BankForm
                axiosRoute="banks"
                Title="ব্যাংক"
                editTitle="ব্যাংক পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/bank_deposit"
            element={
              <BankDeposit
                axiosRoute="bank-deposit"
                Title="ব্যাংক ডিপোজিট"
                editTitle="ব্যাংক ডিপোজিট পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/employee_salary"
            element={
              <EmployeeSalaryForm
                axiosRoute="employee-salary"
                Title="কর্মচারীর বেতন"
                editTitle=" কর্মচারীর বেতন পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/expanse"
            element={
              <ExpanceForm
                Title="খরচ"
                editTitle="খরচ পরিবর্তন"
                axiosRoute="expanse"
              />
            }
          />
          <Route
            path="/admin/loan_giver"
            element={
              <LoanGiverForm
                axiosRoute="loan-giver"
                Title="ঋণ দাতা"
                editTitle="ঋণ দাতা পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/loan"
            element={
              <LoanForm axiosRoute="loan" Title="ঋণ" editTitle="ঋণ পরিবর্তন" />
            }
          />
          <Route
            path="/admin/loan_payment"
            element={
              <LoanPaymentForm
                axiosRoute="loan-payment"
                Title="ঋণ পরিশোধ"
                editTitle="ঋণ পরিশোধ পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/add_area"
            element={
              <AreaForm
                axiosRoute="area"
                Title="এরিয়া"
                editTitle="এরিয়া পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/add_branch"
            element={
              <BranchForm
                axiosRoute="department"
                Title="শাখা"
                editTitle="শাখা পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/connection_type"
            element={
              <ConnectionForm
                axiosRoute="connection"
                Title="কানেকশন ধরণ  "
                editTitle="কানেকশন ধরণ পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/add_employee"
            element={
              <EmployeeForm
                axiosRoute="employees"
                Title="কর্মচারী"
                editTitle="কর্মচারী পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/add_designation"
            element={
              <DesignationForm
                axiosRoute="designation"
                Title="পদবি"
                editTitle="পদবি পরিবর্তন"
              />
            }
          />
          <Route
            path="/admin/account_role"
            element={
              <AccountRoleForm
                axiosRoute="register"
                Title="Acoount Role"
                editTitle="Edit Account Role"
              />
            }
          />
          <Route
            path="/admin/changepassword"
            element={<ChangePassword axiosRoute="change-password" />}
          />
          <Route path="/admin/report/" element={<Reports />} />
          <Route
            path="/admin/report/bill-report"
            element={<BillReport axiosRoute="bill-report" />}
          />
          <Route
            path="/admin/report/billreportinvoice"
            element={<BillReportInvoice />}
          />
          <Route
            path="/admin/report/paid-bill-report"
            element={<PaidBillReport axiosRoute="paid-bill-report" />}
          />
          <Route
            path="/admin/report/paidbillinvoice"
            element={<PaidBillInvoice />}
          />
          <Route
            path="/admin/report/unpaid-bill-report"
            element={<UnpaidBillReport axiosRoute="unpaid-bill-report" />}
          />
          <Route
            path="/admin/report/unpaidbillinvoice"
            element={<UnPaidBillInvoice />}
          />
          <Route
            path="/admin/report/bankreport"
            element={<BankDepositReport axiosRoute="bank-deposit-report" />}
          />
          <Route
            path="/admin/report/bankreportinvoice"
            element={<BankDepositReportInvoice />}
          />
          <Route
            path="/admin/report/loanreport"
            element={<LoanReport axiosRoute="loan-report" />}
          />
          <Route
            path="/admin/report/loanreportinvoice"
            element={<LoanReportInvoice />}
          />
          <Route
            path="/admin/report/unit-report"
            element={<UnitReport axiosRoute="unit-report" />}
          />
          <Route path="/admin/report/unitinvoice" element={<UnitInvoice />} />
          <Route
            path="/admin/report/expansereport"
            element={<ExpanceReport axiosRoute="expanse-report" />}
          />
          <Route
            path="/admin/report/expansereportinvoice"
            element={<ExpanceReportInvoice />}
          />
          <Route
            path="/admin/report/expanceincomereport"
            element={<ExpanceIncomeReport axiosRoute="expanse-income-report" />}
          />
          <Route
            path="/admin/report/simplereport"
            element={
              <ExpanceIncomeSummmeryReport axiosRoute="expanse-income-summary-report" />
            }
          />
          <Route
            path="/admin/report/simplereportinvoice"
            element={<ExpanceIncomeSummeryReportInvoice />}
          />

          <Route
            path="/admin/report/expanseincomereportinvoice"
            element={<ExpanceIncomeReportInvoice />}
          />
          <Route
            path="/admin/report/cashreport"
            element={<CashReport axiosRoute="cash-balance-report" />}
          />
          <Route
            path="/admin/report/cashreportinvoice"
            element={<CashReportInvoice />}
          />
          <Route path="/admin/report/loanreport" element={<LoanReport />} />
          <Route
            path="/admin/report/singleinvoice"
            element={<PrintSingleInvoice />}
          />
          <Route
            path="/admin/report/sub-meter-report"
            element={<SubMeterReport axiosRoute="sub-meter-report" />}
          />
          <Route
            path="/admin/report/submeterinvoice"
            element={<SubMeterInvoice />}
          />

          {/* <Route path="/admin/customer">
            <Route index element={<List />} />

            <Route path="/admin/new" element={<New title="Add New User" />} />
          </Route> */}
          {/* <Route path="/admin/products">
            <Route index element={<List />} />
            <Route path=":productId" element={<Single />} />
            <Route
              path="/admin/new"
              element={<New title="Add New Product" isImage="NULL" />}
            />
          </Route> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
