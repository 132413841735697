import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postItem } from "../../store/allSlice";
import { useNavigate } from "react-router-dom";
import logo from "./login_logo.png";

const Login = ({ axiosRoute }) => {
  let clearTime;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const SubmitHandler = (e) => {
    e.preventDefault();

    if (username !== "" && password !== "") {
      const loginData = {
        user_name: username,
        password: password,
      };
      dispatch(postItem(loginData, axiosRoute));

      clearTime = setTimeout(() => {
        navigate("/admin/dashboard");
      }, 1500);

      return;
    } else {
      navigate("/admin/login");
    }

    return;
  };
  clearTimeout(clearTime);
  return (
    <div className="loginMainContainer">
      <div className="loginContainer">
        <div className="loginItem">
          <div className="circleLogo">
            <img width={"100px"} height={"100px"} src={logo} alt="" />
          </div>
          <h2 style={{ textTransform: "uppercase", marginBottom: "20px" }}>
            bill Management system
          </h2>
        </div>
        <form onSubmit={SubmitHandler}>
          <div className="loginInputContainer">
            <div>
              <label>User Name</label>
              <input
                placeholder="user name"
                onChange={(e) => setUserName(e.target.value)}
                className="loginInput"
                type="text"
                value={username}
              />
            </div>
            <div style={{ marginTop: "15px" }}>
              <label>Password</label>
              <input
                placeholder="password"
                className="loginInput"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="loginButton">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
