// import "./newform.scss";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Billsidebar from "../billsidebar/BillSidebar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EmployeeTable from "./../billTable/EmployeeTable";
import PaginationButton from "../UI/PaginationButton";
import Select from "react-select";
import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
} from "../../store/allSlice";
import { AddEmployee } from "../../billFormData";
import Navbar from "../navbar/Navbar";

const EmployeeForm = ({ Title, editTitle, axiosRoute }) => {
  document.title = "Employee";
  const dispatch = useDispatch();
  const [editId, setEditId] = useState(null);
  const [allData, setAllData] = useState({});
  const [editValue, setEditValue] = useState({});

  const [selectValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const [genderSelect, setGenderSelect] = useState({});
  const [designationSelect, setDesignationSelect] = useState({});
  const [branchSelect, setBranchSelect] = useState({});

  let { data, showButton, Edit, search } = useSelector((state) => state.area);
  let employeesData = data?.employees;
  let searchDesignation = data?.search_designation;
  let searchBranch = data?.search_branch;
  let searchGender = data?.search_gender;

  let key = null;
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = (employeesData ?? data)?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  let dropdownKey = {
    gender: searchGender,
    designation_id: searchDesignation,
    companyId: searchBranch,
  };
  const onChangeHandler = (event, navigate) => {
    let { name, value, checked } = event.target;

    if (Edit) {
      setEditValue({
        ...editValue,
        [name]: event.target.type === "checkbox" ? checked : value,
        [key]: Object.values(selectValue)[1],
      });
    } else {
      setAllData({
        ...allData,
        [name]: event.target.type === "checkbox" ? checked : value,
        [key]: Object.values(selectValue)[1],
      });
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (Edit) {
      dispatch(editItem(editId, editValue, axiosRoute));
    } else {
      dispatch(postItem(allData, axiosRoute));
    }
    dispatch(searchBarHandlerHide(true));
  };
  const onChangeSelectedValue = (e, k) => {
    if (Edit) {
      setEditValue({
        ...editValue,
        [k]: e.value,
      });
    } else {
      setAllData({
        ...allData,
        [k]: e.value,
      });
    }
    if (k === "gender") {
      return setGenderSelect(e);
    } else if (k === "companyId") {
      return setBranchSelect(e);
    } else if (k === "designation_id") {
      return setDesignationSelect(e);
    }
  };

  const EditHandler = (id) => {
    setEditId(id);
    let EmployeeData = employeesData.find((item) => item.id === id);
    setEditValue(EmployeeData);
  };
  const handleReset = () => {
    setAllData({});
    setEditValue({});
  };
  const AddHandler = () => {
    dispatch(setShowButton());
    handleReset();
    dispatch(dataAdd());
  };
  const GoBack = () => {
    dispatch(dataAdd());
    dispatch(setShowButtonHide());
  };
  return (
    <div className="newform">
      <Navbar />
      <Billsidebar />
      <div className="newContainer">
        <div className="top">
          <h1>{Edit ? editTitle : Title}</h1>
          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        <div className="showEntites">
          <span>show </span>
          <select onChange={(event) => setPostPerPage(event.target.value)}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span> entites</span>
        </div>
        {showButton ? (
          <div className="bottom">
            <div className="right">
              <form id="clear_data" onSubmit={onSubmitHandler}>
                <div className="form_input">
                  {AddEmployee.map((input, index) => {
                    if (input.type === "select") {
                      function selectionFunction(p) {
                        const lgSelectData = dropdownKey[p]?.filter(
                          (item) => item.value === editValue[input.db_name]
                        );
                        switch (p) {
                          case "gender":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit &&
                                  (Object.keys(genderSelect).length === 0 ||
                                    branchSelect === undefined)
                                    ? lgSelectData[0]
                                    : Object.keys(genderSelect).length === 0
                                    ? searchGender[0]
                                    : genderSelect
                                }
                                defaultValue={genderSelect}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchGender}
                              />
                            );

                          case "designation_id":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit &&
                                  Object.keys(designationSelect).length === 0
                                    ? lgSelectData[0]
                                    : Object.keys(designationSelect).length ===
                                      0
                                    ? searchDesignation[0]
                                    : designationSelect
                                }
                                defaultValue={designationSelect}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchDesignation}
                              />
                            );
                          case "companyId":
                            return (
                              <Select
                                className="search_dropdown"
                                name={input.db_name}
                                value={
                                  Edit &&
                                  (Object.keys(branchSelect).length === 0 ||
                                    branchSelect === undefined)
                                    ? lgSelectData[0]
                                    : Object.keys(branchSelect).length === 0
                                    ? searchBranch[0]
                                    : branchSelect
                                }
                                defaultValue={branchSelect}
                                onChange={(e) =>
                                  onChangeSelectedValue(e, input.db_name)
                                }
                                options={searchBranch}
                              />
                            );

                          default:
                            return;
                        }
                      }
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          {selectionFunction(input.db_name)}
                        </div>
                      );
                    } else {
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <input
                            className={
                              input.readOnly ? "input_readOnly" : "input"
                            }
                            autoComplete="off"
                            value={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                            }
                            onChange={onChangeHandler}
                            name={input.db_name}
                            checked={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                            }
                            type={input.type}
                            placeholder={input.placeholder}
                            required={input.required}
                            readOnly={input.readOnly}
                            hidden={input.hidden}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="form_button">
                  <button className="button" type="submit">
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => GoBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            <EmployeeTable
              EditHandler={EditHandler}
              axiosRoute={axiosRoute}
              currentPosts={currentPosts}
              setCount={setCount}
              setPostPerPage={setPostPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
            />

            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={
                  search.trim() === "" ? employeesData?.length : count
                }
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeForm;
