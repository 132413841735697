export const Area = [
  {
    id: 1,
    label: "এরিয়ার নাম ",
    db_name: "area_name",
    type: "text",
    placeholder: "",
    required: true,
  },
  {
    id: 2,
    label: "এরিয়ার বর্ণনা",
    type: "text",
    placeholder: "",
    db_name: "description",
    required: false,
  },
  // {
  //   id: 3,
  //   label: "এক্টিভ/ডিএক্টিভ ",
  //   type: "checkbox",
  //   db_name: "is_active",
  //   placeholder: "True",
  //   required: false,
  // },
];

export const Branch = [
  {
    id: 1,
    label: "শাখার নাম ",
    type: "text",
    placeholder: "শাখার নাম... ",
    db_name: "dep_description",
    required: true,
  },
  // {
  //   id: 2,
  //   label: "এক্টিভ/ডিএক্টিভ ",
  //   type: "checkbox",
  //   placeholder: "True",
  //   db_name: "is_active",
  //   required: false,
  // },
];

export const ConnectionType = [
  {
    id: 1,
    label: "সংযোগের ধরণ",
    type: "text",
    placeholder: "কানেকশন টাইপ ",
    db_name: "name",
    required: true,
  },
  {
    id: 2,
    label: "ইউনিট প্রাইস",
    type: "number",
    placeholder: "৳ 00.00",
    db_name: "unit_price",
    required: true,
  },
  {
    id: 3,
    label: "লাইন চার্জ ",
    type: "number",
    placeholder: "৳ 00.00",
    db_name: "line_charge",
    required: true,
  },
  {
    id: 4,
    label: "সার্ভিস চার্জ ",
    type: "number",
    placeholder: "৳ 00.00",
    db_name: "service_charge",
    required: true,
  },
  {
    id: 5,
    label: "ডিমান্ড চার্জ ",
    type: "number",
    placeholder: "৳ 00.00",
    db_name: "demand_charge",
    required: true,
  },
  {
    id: 6,
    label: "লেট ফী ",
    type: "number",
    placeholder: "৳ 00.00",
    db_name: "late_fee",
    required: true,
  },
  {
    id: 7,
    label: "ইউনিট স্টার্ট",
    type: "number",
    placeholder: "৳ 00.00",
    db_name: "unit_start",
    required: true,
  },
  {
    id: 8,
    label: "লোয়েস্ট চার্জ",
    type: "text",
    placeholder: "৳ 00.00",
    db_name: "lowest_charge",
    required: true,
  },
  {
    id: 9,
    label: "ট্যাক্স/ভ্যাট(%)",
    type: "text",
    placeholder: "৳ 00.00",
    db_name: "tax",
    required: true,
  },
  // {
  //   id: 10,
  //   label: "এক্টিভ / ডিএক্টিভ",
  //   type: "checkbox",
  //   db_name: "is_active",

  // },
];

export const AddEmployee = [
  {
    id: 1,
    label: "নাম",
    type: "text",
    placeholder: "নাম লিখুন...",
    required: true,
    db_name: "name",
  },
  {
    id: 2,
    label: "ইমেইল",
    type: "email",
    placeholder: "xyz@dotxit.com",
    required: true,
    db_name: "email",
  },
  {
    id: 3,
    label: "পদবি ",
    type: "select",
    placeholder: "Select Designation",
    required: true,
    db_name: "designation_id",
  },
  {
    id: 4,
    label: "মোবাইল নং ",
    type: "phone",
    placeholder: "+880 XXX XXX-XXX",
    required: false,
    maxLength: 11,
    db_name: "mobile",
  },

  {
    id: 5,
    label: "ঠিকানা ",
    type: "text",
    placeholder: "ঠিকানা ",
    required: true,
    db_name: "address",
  },
  {
    id: 6,
    label: "শাখা",
    type: "select",
    placeholder: "Select Branch",
    required: true,
    db_name: "companyId",
  },
  {
    id: 7,
    label: "Gender",
    type: "select",
    placeholder: "Select",
    required: true,
    db_name: "gender",
  },
  // {
  //   id: 7,
  //   label: "Unit Start",
  //   type: "text",
  //   placeholder: "SINNOMOL",
  //   required: false,
  // },
];

export const EmployeeSalary = [
  {
    id: 1,
    label: "কর্মচারীর নাম:",
    type: "select",
    placeholder: "নির্বাচন করুন",
    required: true,
    db_name: "employee_id",
  },
  {
    id: 2,
    label: "বেতনের মাস :",
    type: "month",
    placeholder: "date",
    db_name: "month",
    required: true,
  },
  {
    id: 3,
    label: " বেতনের পরিমান *:",
    type: "number",
    placeholder: "",
    required: true,
    db_name: "amount",
  },
  {
    id: 4,
    label: "পরিশোধের তারিখ *: ",
    type: "date",
    placeholder: "",
    required: true,
    db_name: "paid_date",
  },
  // {
  //   id: 5,
  //   label: "একটিভ / ডিএক্টিভ",
  //   type: "checkbox",
  //   placeholder: "",
  //   required: true,
  //   db_name: "is_active",
  // },
];

export const Designation = [
  {
    id: 1,
    label: "পদবি (ম্যানেজার/অফিস ) ",
    type: "text",
    placeholder: "",
    required: true,
    db_name: "designation_name",
  },
  {
    id: 2,
    label: "পদবি শর্ট নাম ( CEO/MD/DM )",
    type: "text",
    placeholder: "",
    db_name: "designation_short_name",
  },
  // {
  //   id: 3,
  //   label: "Is Active",
  //   type: "checkbox",
  //   placeholder: "True",
  //   db_name:"parent_id"
  // },
];

export const Customer = [
  {
    id: 1,
    label: "মিটার অনুসন্ধান",
    type: "select",
    placeholder: "নির্বাচন করুন",
    db_name: "parent_id",
  },
  {
    id: 2,
    label: "গ্রাহক নং :",
    type: "text",
    placeholder: "Auto Genarate",
    db_name: "customer_no",
    required: true,
  },
  {
    id: 3,
    label: "সংযোগের ধরন :",
    type: "select",
    placeholder: "সংযোগের ধরন নির্বাচন করুন",
    db_name: "connection_type_id",
    required: true,
  },
  {
    id: 4,
    label: "গ্রাহকের নাম",
    type: "text",
    placeholder: "নাম লিখুন ",
    required: true,
    db_name: "customer_name",
  },
  {
    id: 5,
    label: "মিটার নং :",
    type: "text",
    placeholder: "মিটার নং লিখুন",
    db_name: "meter_no",
    required: true,
  },
  {
    id: 6,
    label: "ঠিকানা:",
    type: "select",
    placeholder: "ঠিকানা নির্বাচন করুন",
    required: true,
    db_name: "area_id",
  },
  {
    id: 7,
    label: "পিতা/স্বামী নাম",
    type: "text",
    placeholder: "পিতা/স্বামী নাম লিখুন",
    required: true,
    db_name: "guardian_name",
  },
  {
    id: 8,
    label: "সংযোগের তারিখ : ",
    type: "date",
    db_name: "connection_date",
    required: true,
  },
  {
    id: 9,
    label: "শাখা :",
    type: "select",
    placeholder: "নির্বাচন করুন",
    db_name: "branch_id",
    required: true,
  },

  {
    id: 10,
    label: "মোবাইল নং : ",
    type: "text",
    placeholder: "01X-XXX-XXXXX",
    db_name: "mobile_no",
    maxLength: 11,
  },

  {
    id: 11,
    label: "পরিচয় পত্র নং :",
    type: "text",
    placeholder: "পরিচয় পত্র নং",
    required: true,
    db_name: "national_id",
    maxLength: 17,
  },
  {
    id: 12,
    label: "গ্রাহক লিঙ্গ :",
    type: "select",
    placeholder: "নির্বাচন করুন",
    required: true,
    db_name: "gender",
  },
  {
    id: 13,
    label: "সংযোগ ফি",
    type: "number",
    placeholder: "৳ 000",
    required: true,
    db_name: "connection_charge",
  },

  {
    id: 14,
    label: "প্রদান টাকা :",
    type: "number",
    placeholder: "৳ 000",
    required: true,
    db_name: "connection_payment",
  },

  {
    id: 15,
    label: "রক্তের গ্রুপ :",
    type: "select",
    placeholder: "নির্বাচন করুন",
    // required: true,
    db_name: "blood_group",
  },
  {
    id: 16,
    label: "বকিয়া টাকা :",
    type: "number",
    placeholder: "৳ 000",
    db_name: "connection_due",
    readOnly: true,
  },
  {
    id: 17,
    label: "বিস্তারিত :",
    type: "text",
    placeholder: "বিস্তারিত লিখুন ",
    db_name: "description",
  },
];

export const AddBank = [
  {
    id: 1,
    label: "হিসাব নাম / Name",
    type: "text",
    placeholder: "account Name",
    required: true,
    db_name: "b_name",
  },
  {
    id: 2,
    label: "হিসাব নম্বর / Account No ",
    type: "number",
    placeholder: "xxx xxx xxx xxx",
    required: true,
    db_name: "b_account_no",
  },
  // {
  //   id: 3,
  //   label: "Is Active",
  //   type: "checkbox",
  //   db_name: "is_active",
  // },
];

export const BankDepositDataForm = [
  {
    id: 1,
    label: "ব্যাংক সিলেক্ট করুন",
    type: "select",
    placeholder: "Select Bank",
    required: true,
    db_name: "bank_id",
  },
  {
    id: 2,
    label: "জমা দাওয়ার পরিমান",
    type: "number",
    placeholder: "Deposit Amount",
    required: true,
    db_name: "deposit_amount",
    readOnly: false,
  },
  {
    id: 3,
    label: "তারিখ",
    type: "date",
    placeholder: "",
    required: true,
    db_name: "date",
  },

  {
    id: 4,
    label: "টাকার পরিমান ",
    type: "number",
    placeholder: "000",
    required: true,
    db_name: "balance",
    readOnly: true,
  },
  {
    id: 5,
    label: "সর্ব শেষ টাকার পরিমান",
    type: "number",
    placeholder: "000",
    required: true,
    db_name: "closing_balance",
    readOnly: true,
  },
];
export const BillRegister = [
  {
    id: 1,
    label: "গ্রাহক অনুসন্ধান",
    type: "select",
    placeholder: "Select ",
    required: true,
    db_name: "customer_id",
  },
  {
    id: 2,
    label: "গ্রাহক নং",
    type: "text",
    placeholder: "customer no",
    readOnly: true,
    required: true,
    db_name: "customer_no",
  },
  {
    id: 3,
    label: "মিটার নং",
    type: "text",
    placeholder: "meter no",
    readOnly: true,
    required: true,
    db_name: "customer_meter_no",
  },
  {
    id: 4,
    label: "বর্তমান ইউনিট",
    type: "number",
    placeholder: "type unit",
    required: true,
    db_name: "current_unit",
  },
  {
    id: 5,
    label: "বকেয়া বিল",
    type: "number",
    placeholder: "Closing Balance",
    required: true,
    db_name: "due_bill",
  },
  {
    id: 6,
    label: "বিলের মাস",
    type: "month",
    placeholder: "",
    required: true,
    db_name: "month",
  },
  {
    id: 7,
    label: "পূর্বের ইউনিট",
    type: "number",
    placeholder: "",
    required: true,
    db_name: "previous_unit",
    // readOnly:true
  },
  {
    id: 8,
    label: "বকেয়া বিলের জরিমানা ",
    type: "number",
    placeholder: "",
    required: true,
    readOnly: true,
    db_name: "late_fee",
  },
  {
    id: 9,
    label: "পরিশোধের শেষ তারিখ",
    type: "date",
    placeholder: "set data",
    required: true,
    db_name: "last_paid_date",
  },
  {
    id: 10,
    label: "ব্যবহৃত ইউনিট",
    type: "number",
    placeholder: "",
    readOnly: true,
    required: true,
    db_name: "uses_unit",
  },
  {
    id: 11,
    label: "অতিরিক্ত চার্জ",
    type: "number",
    placeholder: "",
    required: true,
    db_name: "extra_charge",
  },
  {
    id: 12,
    label: "অতিরিক্ত চার্জের বর্ননা",
    type: "text",
    placeholder: "",
    // required: true,
    db_name: "extra_charge_description",
  },
  {
    id: 13,
    label: "ছাড়",
    type: "number",
    placeholder: "",
    // required: true,
    db_name: "discount",
  },
  {
    id: 14,
    label: "বকেয়া মিটার সংযোগ ফি",
    type: "number",
    placeholder: "meter due",
    readOnly: true,
    // required: true,
    db_name: "disconnection_charge",
  },
  {
    id: 15,
    label: "সর্বমোট বিল",
    type: "number",
    placeholder: "Deposit Amount",
    readOnly: true,
    // required: true,
    db_name: "total_bill",
  },
];
export const PaymentCollection = [
  {
    id: 1,
    label: "গ্রাহক নং",
    type: "select",
    placeholder: "Select ",
    required: true,
    db_name: "bill_id",
  },
  {
    id: 2,
    label: "বিলের মাস  ",
    type: "text",
    placeholder: "",
    required: true,
    readOnly: true,
    db_name: "bill_month",
  },
  {
    id: 3,
    label: "বিলের পরিমান ",
    type: "number",
    placeholder: "",
    required: true,
    readOnly: true,
    db_name: "bill_amount",
  },
  {
    id: 4,
    label: "পরিশোধিত টাকার পরিমান",
    type: "number",
    placeholder: "",
    // required: true,
    readOnly: false,
    db_name: "amount",
  },
  {
    id: 5,
    label: "ছাড়",
    type: "number",
    placeholder: "",
    required: true,
    db_name: "discount_amount",
  },
  {
    id: 6,
    label: "অগ্রিম / বকেয়া ",
    type: "number",
    placeholder: "",
    // required: true,
    readOnly: true,
    db_name: "due_deposit",
  },
];

export const ExpanseFormData = [
  {
    id: 1,
    label: " খরচের তারিখ : ",
    type: "date",
    placeholder: "",
    db_name: "expanse_date",
  },
  {
    id: 2,
    label: "বিবরন",
    type: "text",
    placeholder: "খরচের কারণ লিখুন",
    required: true,
    db_name: "description",
  },
  {
    id: 3,
    label: "খরচের পরিমান",
    type: "number",
    placeholder: "000",
    required: true,
    db_name: "amount",
  },
  // {
  //   id: 4,
  //   label: "সর্ব শেষ টাকার পরিমান ",
  //   type: "numbar",
  //   placeholder: "000",
  //   required: true,
  //   // db_name: "amount",
  //   readOnly: true,
  // },
];

export const LoanGiver = [
  {
    id: 1,
    label: "ঋণ দাতার নাম: ",
    type: "text",
    placeholder: "Full name",
    required: true,
    db_name: "name",
  },
  {
    id: 2,
    label: "ঠিকানা",
    type: "text",
    placeholder: "Address",
    db_name: "address",
    required: true,
  },
  {
    id: 3,
    label: "মোবাইল নং",
    type: "number",
    placeholder: "01X-XXX-XXXXX",
    db_name: "mobile_no",
    maxLength: 11,
    required: true,
  },
  // {
  //   id: 4,
  //   label: "Is Active",
  //   type: "checkbox",
  //   db_name: "is_active",
  // },
];

export const Loan = [
  {
    id: 1,
    label: "ঋণ নং: ",
    type: "text",
    placeholder: "L-0000",
    db_name: "loan_no",
    required: true,
  },
  {
    id: 2,
    label: "ঋণ দাতা :",
    type: "select",
    placeholder: "নির্বাচন করুন",
    db_name: "lg_id",
    required: true,
  },
  {
    id: 3,
    label: "তারিখ:",
    type: "date",
    placeholder: "তারিখ নির্বাচন করুন",
    db_name: "date",
    required: true,
  },
  {
    id: 4,
    label: "পরিমান :",
    type: "number",
    placeholder: "৳000",
    db_name: "amount",
    required: true,
  },
  // {
  //   id: 5,
  //   label: "Is Active",
  //   type: "checkbox",
  //   db_name: "is_active",
  // },
];

export const LoanPayment = [
  {
    id: 1,
    label: "ঋণ নং ",
    type: "select",
    placeholder: "",
    db_name: "ln_id",
  },
  {
    id: 2,
    label: "পরিশোধের তারিখ",
    type: "date",
    placeholder: "",
    db_name: "date",
    autoFocus: true,
    required: true,
  },
  {
    id: 3,
    label: "পরিশোধিত টাকার পরিমান",
    type: "number",
    placeholder: "",
    db_name: "amount",
    required: true,
  },
  {
    id: 4,
    label: "বকেয়া টাকার পরিমান",
    type: "number",
    placeholder: "",
    readOnly: true,
    db_name: "due_amount",
  },
  {
    id: 5,
    label: "ঋণের টাকার পরিমান",
    type: "number",
    placeholder: "",
    readOnly: true,
    db_name: "loan_amount",
  },
  {
    id: 6,
    label: "পূর্বের পরিশোধিত টাকার পরিমান",
    type: "number",
    placeholder: "",
    readOnly: true,
    db_name: "given_amount",
  },
];

// Report Section
export const AllInvoice = [
  {
    id: 1,
    label: "From",
    type: "text",
    placeholder: "Invoice No",
    db_name: "from",
  },
  {
    id: 2,
    label: "To",
    type: "text",
    placeholder: "Invoice No",
    db_name: "to",
  },
  {
    id: 3,
    label: "Month",
    type: "month",
    placeholder: "Month",
    db_name: "month",
  },
];
export const BillReportForm = [
  {
    id: 1,
    label: "কাস্টমার সার্চ ",
    type: "select",
    placeholder: "select",
    db_name: "customer_id",
  },
  {
    id: 2,
    label: "তারিখ হতে",
    type: "date",
    placeholder: "date",
    db_name: "from",
  },
  {
    id: 3,
    label: "তারিখ পর্যন্ত",
    type: "date",
    placeholder: "date",
    db_name: "to",
  },
];
export const PaidBillReportForm = [
  {
    id: 1,
    label: "Customer Search",
    type: "select",
    placeholder: "select",
    db_name: "customer_no",
  },
  {
    id: 2,
    label: "Month",
    type: "month",
    placeholder: "month",
    db_name: "month",
  },
];
export const UnpaidBillReportForm = [
  {
    id: 1,
    label: "Customer Search",
    type: "select",
    placeholder: "select",
    db_name: "customer_no",
  },
  {
    id: 2,
    label: "Month",
    type: "month",
    placeholder: "month",
    db_name: "month",
  },
];
export const UnitReportForm = [
  {
    id: 1,
    label: "Customer Search",
    type: "select",
    placeholder: "select",
    db_name: "customer_no",
  },
  {
    id: 2,
    label: "Month",
    type: "month",
    placeholder: "month",
    db_name: "from",
  },
  // {
  //   id: 3,
  //   label: "To",
  //   type: "month",
  //   placeholder: "month",
  //   db_name: "to",
  // },
];
export const ExpanseReportForm = [
  {
    id: 1,
    label: "তারিখ হতে",
    type: "date",
    placeholder: "date",
    db_name: "from",
  },
  {
    id: 2,
    label: "তারিখ পর্যন্ত",
    type: "date",
    placeholder: "date",
    db_name: "to",
  },
];
export const IncomeExpanseReportForm = [
  {
    id: 1,
    label: "তারিখ হতে",
    type: "date",
    placeholder: "date",
    db_name: "from",
  },
  {
    id: 2,
    label: "তারিখ পর্যন্ত",
    type: "date",
    placeholder: "date",
    db_name: "to",
  },
];
export const IncomeExpanseSimpleReportForm = [
  {
    id: 1,
    label: "তারিখ হতে",
    type: "date",
    placeholder: "date",
    db_name: "from",
  },
  {
    id: 2,
    label: "তারিখ পর্যন্ত",
    type: "date",
    placeholder: "date",
    db_name: "to",
  },
];
export const CashReportForm = [
  {
    id: 1,
    label: "তারিখ হতে",
    type: "date",
    placeholder: "date",
    db_name: "from",
  },
  {
    id: 2,
    label: "তারিখ পর্যন্ত",
    type: "date",
    placeholder: "date",
    db_name: "to",
  },
];
export const LoanReportForm = [
  {
    id: 1,
    label: "তারিখ হতে",
    type: "date",
    placeholder: "date",
    db_name: "customer_no",
  },
  {
    id: 2,
    label: "তারিখ পর্যন্ত",
    type: "date",
    placeholder: "date",
    db_name: "from",
  },
];
export const SubMeterReportForm = [
  {
    id: 1,
    label: "Meter No.",
    type: "select",
    placeholder: "select",
    db_name: "customer_no",
  },
];

export const DueBillReportForm = [
  {
    id: 1,
    label: "Customer Search",
    type: "select",
    placeholder: "select",
    db_name: "customer_no",
  },
  {
    id: 2,
    label: "month",
    type: "month",
    placeholder: "Month",
    db_name: "month",
  },
];
export const BankReportForm = [
  {
    id: 1,
    label: "তারিখ হতে",
    type: "date",
    placeholder: "date",
    db_name: "from",
  },
  {
    id: 2,
    label: "তারিখ পর্যন্ত",
    type: "date",
    placeholder: "date",
    db_name: "to",
  },
];
