import { createSlice } from "@reduxjs/toolkit";
import Axios from "../Axios";
import toast from "react-hot-toast";
export const userSlice = createSlice({
  name: "users",
  initialState: {
    data: [],
  },
  reducers: {
    setFetchItem: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setFetchItem } = userSlice.actions;
export default userSlice.reducer;
export const fetchItem = (url) => {
  return async (dispatch, getState) => {
    try {
      const response = await Axios.get(`/${url}`);

      dispatch(setFetchItem(response.data));
    } catch (error) {
      toast(error.response);
    }
  };
};
