import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Billsidebar from "../billsidebar/BillSidebar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PaginationButton from "../UI/PaginationButton";
import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
} from "../../store/allSlice";
import { ExpanseFormData } from "../../billFormData";
import Navbar from "../navbar/Navbar";
import ExpanceTable from "../billTable/ExpanceTable";

const ExpanseForm = ({ Title, editTitle, axiosRoute }) => {
  document.title = "Expance";
  const dispatch = useDispatch();
  const [editId, setEditId] = useState(null);
  const [allData, setAllData] = useState({});
  const [editValue, setEditValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [count, setCount] = useState(0);

  let { data, Edit, showButton, message, search } = useSelector(
    (state) => state.expance
  );
  let expanceData = data;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = (expanceData ?? data)?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const onChangeHandler = (event) => {
    let { name, value, checked } = event.target;

    if (Edit) {
      let balance = 0,
        deposit_amount = 0,
        closing_balance = 0;
      balance =
        event.target.name === "balance"
          ? parseInt(event.target.value)
          : parseInt(editValue.balance);
      deposit_amount =
        event.target.name === "deposit_amount"
          ? parseInt(event.target.value)
          : parseInt(editValue.deposit_amount);
      closing_balance =
        event.target.name === "closing_balance"
          ? parseInt(event.target.value)
          : parseInt(editValue.closing_balance);

      closing_balance = balance - deposit_amount;

      setEditValue({
        ...editValue,
        [name]: event.target.type === "checkbox" ? checked : value,
        closing_balance: closing_balance,
        balance: balance,
        deposit_amount: deposit_amount,
      });
    } else {
      setAllData({
        ...allData,
        [name]: event.target.type === "checkbox" ? checked : value,
      });
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (Edit) {
      dispatch(editItem(editId, editValue, axiosRoute));
    } else {
      dispatch(postItem(allData, axiosRoute));
    }
    dispatch(searchBarHandlerHide(true));
  };

  const EditHandler = (id) => {
    setEditId(id);
    let employeeSData = expanceData?.find((item) => item.id === id);

    setEditValue(employeeSData);
    dispatch(searchBarHandlerHide(false));
  };
  const handleReset = () => {
    setAllData({});
    setEditValue({});
  };
  const AddHandler = () => {
    dispatch(setShowButton());
    handleReset();
    dispatch(dataAdd());
    dispatch(searchBarHandlerHide(false));
  };
  const goBack = () => {
    dispatch(setShowButtonHide());
    dispatch(searchBarHandlerHide(true));
  };
  return (
    <div className="newform">
      <Navbar />
      <Billsidebar />
      <div className="newContainer">
        <div className="top">
          <h1>{Edit ? editTitle : Title}</h1>
          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        <div className="showEntites">
          <span>show </span>
          <select onChange={(event) => setPostPerPage(event.target.value)}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
          <span> entites</span>
        </div>
        {showButton ? (
          <div className="bottom">
            <div className="right">
              <span style={{ color: "red" }}> {message}</span>
              <form id="clear_data" onSubmit={onSubmitHandler}>
                <div className="form_input">
                  {ExpanseFormData.map((input, index) => {
                    return (
                      <div className="formInput" key={input.id}>
                        <label>{input.label}</label>
                        <input
                          className={
                            input.readOnly ? "input_readOnly" : "input"
                          }
                          autoComplete="off"
                          value={
                            Edit
                              ? editValue[input.db_name]
                              : allData[input.db_name]
                          }
                          onChange={onChangeHandler}
                          name={input.db_name}
                          checked={
                            Edit
                              ? editValue[input.db_name]
                              : allData[input.db_name]
                          }
                          type={input.type}
                          placeholder={input.placeholder}
                          required={input.required}
                          readOnly={input.readOnly}
                          hidden={input.hidden}
                        />
                      </div>
                    );
                    // }
                  })}
                </div>
                <div className="form_button">
                  <button className="button" type="submit">
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => goBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            <ExpanceTable
              EditHandler={EditHandler}
              axiosRoute={axiosRoute}
              currentPosts={currentPosts}
              setCount={setCount}
              setPostPerPage={setPostPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
            />

            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={search.trim() === "" ? expanceData?.length : count}
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpanseForm;
