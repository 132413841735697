import React from "react";
import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import Spiner from "../UI/Spiner";

const BankDepositReportTable = ({ items }) => {
  const { isLoading } = useSelector((state) => state.area);
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table  aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">ক্রমিক নং</TableCell>
                <TableCell className="tableCell">বিল তৈরির তারিখ</TableCell>
                <TableCell className="tableCell">জমার পরিমান</TableCell>
                <TableCell className="tableCell">
                  সর্ব শেষ টাকার পরিমান
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {items?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="tableCell">
                      {index.length === 0 ? index + 1 : null}
                    </TableCell>
                    <TableCell className="tableCell">{item.date}</TableCell>
                    <TableCell className="tableCell">
                      {item.deposit_amount}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.closing_balance}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default BankDepositReportTable;
