import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import Spiner from "../UI/Spiner";
import { fetchItem } from "../../store/allSlice";
import { DigitConvert } from "../../store/customDate";

const UnitReportTable = ({ axiosRoute }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.area);
  let UnitResult = data?.results;
  let totalUnit = 0;
  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
  }, [dispatch]);
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table  aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">ক্রমিক নং</TableCell>
                <TableCell className="tableCell">বিল তৈরির তারিখ</TableCell>
                <TableCell className="tableCell">গ্রাহক নং</TableCell>
                <TableCell className="tableCell">গ্রাহকের নাম</TableCell>
                <TableCell className="tableCell">বিলের মাস</TableCell>
                <TableCell className="tableCell">মিটার নং </TableCell>

                <TableCell className="tableCell">ইউনিট</TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {UnitResult?.map((item, index) => {
                let current_unit = parseInt(item.uses_unit);

                if ("uses_unit" in item) {
                  totalUnit += current_unit;
                }
                return (
                  <TableRow key={index}>
                    <TableCell className="tableCell">
                      {DigitConvert(index + 1)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.created_date_bn}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.customer_no_bn}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.customer_name}
                    </TableCell>
                    <TableCell className="tableCell">{item.month_bn}</TableCell>

                    <TableCell className="tableCell">
                      {item.customer_meter_no_bn}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.uses_unit_bn}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>মোট</TableCell>
                <TableCell>{DigitConvert(totalUnit)}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default UnitReportTable;
