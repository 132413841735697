import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import Spiner from "../UI/Spiner";
import { fetchItem } from "../../store/allSlice";
import { CustomDayMonthYear, DigitConvert } from "../../store/customDate";

const ExpanceIncomeReportTable = ({ axiosRoute }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((state) => state.area);
  let totalTk = 0;
  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
  }, [dispatch]);
  return (
    <>
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Spiner />
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">ক্রমিক নং</TableCell>
                <TableCell className="tableCell">তারিখ</TableCell>
                <TableCell className="tableCell">গ্রাহক নং</TableCell>
                <TableCell className="tableCell">গ্রাহকের নাম</TableCell>
                <TableCell className="tableCell">আয় অথবা খরচের বিবরন</TableCell>
                <TableCell className="tableCell">আয়ের পরিমান</TableCell>
                <TableCell className="tableCell">খরচের পরিমান</TableCell>
                <TableCell className="tableCell">
                  সর্ব শেষ টাকার পরিমান{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {data?.map((item, index) => {
                if ("income_amount" in item) {
                  totalTk += item.income_amount;
                }
                return (
                  <TableRow key={index}>
                    <TableCell className="tableCell">
                      {DigitConvert(index + 1)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {CustomDayMonthYear(item.date)}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.customer_no}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.customer_name}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.expanse_income_description}
                    </TableCell>
                    <TableCell className="tableCell">
                      {item.income_amount
                        ? DigitConvert(item.income_amount)
                        : null}
                    </TableCell>
                    <TableCell className="tableCell">
                      {/* {DigitConvert(item.expanse_amount)} */}
                    </TableCell>
                    <TableCell className="tableCell"></TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>মোট টাকা</TableCell>
                <TableCell>{DigitConvert(totalTk)}</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default ExpanceIncomeReportTable;
