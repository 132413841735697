import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import Table from "@mui/material/Table";
import PrintIcon from "@mui/icons-material/Print";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Navbar from "../navbar/Navbar";
import BillSidebar from "../billsidebar/BillSidebar";
import { DigitConvert } from "../../store/customDate";

const UnPaidBillInvoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  let totalBill = 0;
  let paidBill = 0;
  let discount = 0;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    documentTitle: "Unpaid Bill Report",
    content: () => componentRef.current,
  });
  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />
      <div className="newContainer">
        <div className="print_grid" style={{ width: "92%" }}>
          <div className="center_invoice-submeter">
            <div className="body" ref={componentRef}>
              <div
                id="main-section-submeter"
                // media="print"
              >
                <TableContainer
                  component={Paper}
                  className="table"
                  classes="invoiceTable"
                  style={{ padding: "0" }}
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={8} className="top-section1">
                          <div
                            className="top-section1"
                            style={{ textAlign: "center" }}
                          >
                            <p>বিসমিল্লাহির রাহমানির রাহিম</p>
                            <p>
                              "বিদ্যুৎ জাতীয় সম্পদ অপচয় রোধে এগিয়ে আসুন,অবৈধ
                              সংযোগ থেকে বিরত থাকুন"
                            </p>
                            <h4>
                              চট্টগ্রাম মহানগর ছিন্নমুল বস্তিবাসি সমন্বয় সংগ্রাম
                              পরিষদ
                            </h4>
                            <p>
                              জঙ্গল ছলিমপুর ছিন্নমুল পূনর্বাসন প্রকল্প, ডাকঘরঃ
                              জাফরাবাদ,থানাঃসিতাকুন্ড,চট্টগ্রাম |
                            </p>
                            <p>বিদ্যুৎ বিতরন বিভাগ</p>
                            <h2 style={{ padding: "0" }}>
                              অপরিশোধিত বিল রিপোর্ট{" "}
                            </h2>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className="tableCell"
                          style={{ width: "5%" }}
                        >
                          ক্রম নং
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ width: "14%" }}
                        >
                          বিল তৈরির তারিখ
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ width: "8%" }}
                        >
                          গ্রাহক নং
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ width: "18%" }}
                        >
                          গ্রাহকের নাম
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ width: "10%" }}
                        >
                          বিলের মাস
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ width: "10%" }}
                        >
                          মোট বিল
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ width: "10%" }}
                        >
                          অপরিশোধিত টাকা
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ width: "5%" }}
                        >
                          ছাড়
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      {state?.map((item, index) => {
                        let TotalAmount = parseInt(item.total_bill);
                        let PaidAmount = parseInt(item.paid_amount);
                        let DiscountAmount = parseInt(item.discount);
                        if (
                          "total_bill" in item &&
                          "paid_amount" in item &&
                          "discount" in item
                        ) {
                          totalBill += TotalAmount;
                          paidBill += PaidAmount;
                          discount += DiscountAmount;
                        }
                        return (
                          <TableRow key={index}>
                            <TableCell className="tableCell">
                              {DigitConvert(index + 1)}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.created_date_bn}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.customer_no_bn}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.customer_name}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.month_bn}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.total_bill_bn}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.paid_amount_bn}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.discount_bn}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>মোট</TableCell>
                        <TableCell>{DigitConvert(totalBill)}</TableCell>
                        <TableCell>{DigitConvert(paidBill)}</TableCell>
                        <TableCell>{DigitConvert(discount)}</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                {/* <!-- BOTTOM div END -->
    <!-- CUSTOMER SECTON START --> */}
              </div>
            </div>
          </div>
        </div>
        <div className="print_option_submeter">
          <div
            className="fixed_button_submeter"
            style={{ width: "15%", right: "2rem" }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <button
                onClick={() => {
                  handlePrint();
                }}
              >
                <PrintIcon
                  style={{
                    fill: "#fff",
                    color: "#fff",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                />
              </button>
              <button
                onClick={() => navigate("/admin/report/unpaid-bill-report")}
              >
                <ReplyAllIcon
                  style={{
                    fill: "#fff",
                    color: "#fff",
                    height: "50px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UnPaidBillInvoice;
