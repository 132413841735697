import React from "react";
import { useEffect, useState } from "react";
import Billsidebar from "../../components/billsidebar/BillSidebar";

// import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";

import { useSelector, useDispatch } from "react-redux";
import { fetchItem } from "../../store/allSlice";
import { fetchItemData } from "../../store/customerSearchSlice";
import Navbar from "../../components/navbar/Navbar";

const Dashboard = ({ axiosRoute, setIsAuthenicated }) => {
  document.title = "Dashboard";
  const [scrollPosition, setScrollPosition] = useState(false);
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchItem(axiosRoute));
    dispatch(fetchItemData("search-customer"));
  }, [dispatch]);

  let { data } = useSelector((state) => state.area);

  let total_user = data?.total_user;
  let monthly_bill_register = data?.monthly_bill_register;
  let bill_register_monthly_amount = data?.bill_register_monthly_amount;
  let paid_monthly_amount = data?.paid_monthly_amount;
  let bill_register_discount_amount=data?.bill_register_discount_amount
  let six_month_paid_bill = data?.six_month_paid_bill;
  let total_bill_register_amount = data?.total_bill_register_amount;
  let total_bill_paid_amount = data?.total_bill_paid_amount;

  const handleScroll = () => {
    if (window.scrollY >= 200) {
      setScrollPosition(true);
    } else if (window.screenTop <= 200) {
      setScrollPosition(false);
    }
  };
  // let access_token=JSON.parse(window.localStorage.getItem('token')??'')

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, [scrollPosition]);

  return (
    <div className="home">
      <Navbar setIsAuthenicated={setIsAuthenicated} />
      <Billsidebar />
      <div className="homeContainer">
        <div className="widgets">
          <Widget type="user" customerdata={total_user} />
          <Widget type="order" monthlyBillRegister={monthly_bill_register} />
          <Widget
            type="earning"
            billRegisterMonthlyAmount={bill_register_monthly_amount}
          />
          <Widget type="discount" billRegisterDiscountAmount={bill_register_discount_amount} />
          <Widget type="balance" paidMonthlyAmount={paid_monthly_amount} />

        </div>
        <div className="charts">
          <Featured
            totalBillRegisterAmount={total_bill_register_amount}
            totalBillPaidAmount={total_bill_paid_amount}
          />
          <Chart
            title="Last 6 Months (Revenue)"
            data={six_month_paid_bill}
            aspect={2 / 1}
          />
        </div>
        {/* <div className="listContainer">
          <div className="listTitle">Latest Transactions</div>
          <Table />
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
